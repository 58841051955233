import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  Time: string;
};

export type AmTask = {
  __typename?: "AmTask";
  completedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  info?: Maybe<Scalars["String"]>;
  taskType: Scalars["String"];
};

export type AmTaskConnection = {
  __typename?: "AmTaskConnection";
  nodes: Array<AmTask>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type BillingCycle = {
  __typename?: "BillingCycle";
  campaignId: Scalars["Int"];
  campaignName: Scalars["String"];
  endsAt: Scalars["DateTime"];
  isCurrent: Scalars["Boolean"];
  startsAt: Scalars["DateTime"];
};

export enum CategoriesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type Category = {
  __typename?: "Category";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type CategoryConnection = {
  __typename?: "CategoryConnection";
  nodes: Array<Category>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ContentItem = {
  __typename?: "ContentItem";
  content: Scalars["String"];
  id: Scalars["Int"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  thumbnailFile?: Maybe<File>;
  title: Scalars["String"];
};

export type ContentItemDelivery = {
  __typename?: "ContentItemDelivery";
  content: Scalars["String"];
  contentOnlyText: Scalars["String"];
  feedbackContentItems: Array<FeedbackContentItem>;
  keyword: Scalars["String"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  partnerLogoUrl?: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  publicUrl: Scalars["String"];
  thumbnailUrl?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ContentStrategyRevisionRequest = {
  comment: Scalars["String"];
  orderContentStrategyId: Scalars["Int"];
};

export type Conversation = {
  __typename?: "Conversation";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  messages: MessageConnection;
  subject: Scalars["String"];
};

export type ConversationMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<MessageSort>;
};

export type ConversationConnection = {
  __typename?: "ConversationConnection";
  nodes: Array<Conversation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreateConversationInput = {
  content: Scalars["String"];
  subject: Scalars["String"];
};

export type CreateConversationResponse = {
  __typename?: "CreateConversationResponse";
  conversation: Conversation;
};

export type CreateCustomerBillingContactInput = {
  customerId: Scalars["Int"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type CreateCustomerBillingContactResponse = {
  __typename?: "CreateCustomerBillingContactResponse";
  customerBillingContact?: Maybe<CustomerBillingContact>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerCampaignInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  campaignCategoryId: Scalars["String"];
  customerId: Scalars["Int"];
  endsAt?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  partnerPackageId?: InputMaybe<Scalars["Int"]>;
  startsAt: Scalars["DateTime"];
};

export type CreateCustomerCampaignResponse = {
  __typename?: "CreateCustomerCampaignResponse";
  customerCampaign: CustomerCampaign;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerHoursOfOperationItemInput = {
  closeAt: Scalars["String"];
  dayIndex: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CreateCustomerInput = {
  accountManagerUserId?: InputMaybe<Scalars["Int"]>;
  categoryId: Scalars["Int"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  gbpUrl?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  portal?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerPaymentMethodSetupIntentInput = {
  customerId: Scalars["Int"];
};

export type CreateCustomerPaymentMethodSetupIntentResponse = {
  __typename?: "CreateCustomerPaymentMethodSetupIntentResponse";
  clientSecret?: Maybe<Scalars["String"]>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerPremiumBusinessListingSpecImageInput = {
  customerId: Scalars["Int"];
  fileId: Scalars["String"];
  imageTypeId: Scalars["String"];
};

export type CreateCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "CreateCustomerPremiumBusinessListingSpecImageResponse";
  customerPremiumBusinessListingSpecImage: CustomerPremiumBusinessListingSpecImage;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerResponse = {
  __typename?: "CreateCustomerResponse";
  customer: Customer;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateMessageInput = {
  content: Scalars["String"];
  conversationId: Scalars["String"];
};

export type CreateMessageResponse = {
  __typename?: "CreateMessageResponse";
  message: Message;
};

export type CreateUserInteractionEventInput = {
  entityId?: InputMaybe<Scalars["Int"]>;
  entityName: Scalars["String"];
  eventData?: InputMaybe<Scalars["String"]>;
  eventType: Scalars["String"];
  userId: Scalars["Int"];
};

export type CreateUserInteractionEventResponse = {
  __typename?: "CreateUserInteractionEventResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type Customer = {
  __typename?: "Customer";
  accountManagerUser?: Maybe<User>;
  accountManagerUserId?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  analyticsReportingInlineFrameUrl?: Maybe<Scalars["String"]>;
  autoPayCustomerPaymentMethod?: Maybe<CustomerPaymentMethod>;
  autoPayCustomerPaymentMethodId?: Maybe<Scalars["Int"]>;
  averageRanking?: Maybe<Scalars["Float"]>;
  billingCycles: Array<BillingCycle>;
  categoryId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currentCustomerCampaign?: Maybe<CustomerCampaign>;
  customerBillingConfig?: Maybe<CustomerBillingConfig>;
  customerBillingContacts: Array<CustomerBillingContact>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerBusinessListingSpecs: CustomerBusinessListingSpecConnection;
  customerCampaignBillingConfig?: Maybe<CustomerCampaignBillingConfig>;
  customerCampaignInvoices?: Maybe<CustomerCampaignInvoiceConnection>;
  customerCampaigns: CustomerCampaignConnection;
  customerHoursOfOperationItems?: Maybe<Array<CustomerHoursOfOperationItem>>;
  customerInvoices?: Maybe<CustomerInvoiceConnection>;
  customerPaymentMethods: CustomerPaymentMethodConnection;
  customerPremiumBusinessListingSpec?: Maybe<CustomerPremiumBusinessListingSpec>;
  customerPremiumBusinessListingSpecImages: CustomerPremiumBusinessListingSpecImageConnection;
  customerQuestionnaire?: Maybe<CustomerQuestionnaire>;
  enableAutoPayInformation?: Maybe<EnableAutopayInformation>;
  gbpUrl?: Maybe<Scalars["String"]>;
  hasMapRanking: Scalars["Boolean"];
  hasPremiumListing: Scalars["Boolean"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  keywordsImproved?: Maybe<Scalars["Int"]>;
  keywordsOnFirstPage?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  partner: Partner;
  phoneNumber?: Maybe<Scalars["String"]>;
  portal: Scalars["String"];
  status: CustomerStatus;
  website: Scalars["String"];
};

export type CustomerAverageRankingArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerBillingCyclesArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCurrentCustomerCampaignArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerBusinessListingSpecsArgs = {
  isListed?: InputMaybe<Scalars["Boolean"]>;
};

export type CustomerCustomerCampaignInvoicesArgs = {
  invoiceDate?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerCampaignInvoiceSort>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CustomerCustomerCampaignsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  endsAt?: InputMaybe<DateQuery>;
  startsAt?: InputMaybe<DateQuery>;
};

export type CustomerCustomerInvoicesArgs = {
  invoiceDate?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerInvoiceSort>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CustomerCustomerPaymentMethodsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerCustomerPremiumBusinessListingSpecImagesArgs = {
  imageTypeId?: InputMaybe<Scalars["String"]>;
};

export type CustomerKeywordsImprovedArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerKeywordsOnFirstPageArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerBillingConfig = {
  __typename?: "CustomerBillingConfig";
  autoPayCustomerPaymentMethod?: Maybe<CustomerPaymentMethod>;
  autoPayCustomerPaymentMethodId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  isAutoPayEnabled: Scalars["Boolean"];
  isBillingEnforced: Scalars["Boolean"];
  isUpfrontPaymentRequired: Scalars["Boolean"];
};

export type CustomerBillingContact = {
  __typename?: "CustomerBillingContact";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
};

export type CustomerBusinessListingSpec = {
  __typename?: "CustomerBusinessListingSpec";
  address: Scalars["String"];
  city: Scalars["String"];
  contactName?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  email?: Maybe<Scalars["String"]>;
  hasPhysicalStorefront: Scalars["Boolean"];
  id: Scalars["Int"];
  isReviewEnabled: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  stateOrProvince: Scalars["String"];
  uberallListingDetails?: Maybe<UberallListingDetailsResponse>;
  zipPostalCode: Scalars["String"];
};

export type CustomerBusinessListingSpecConnection = {
  __typename?: "CustomerBusinessListingSpecConnection";
  nodes: Array<CustomerBusinessListingSpec>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaign = {
  __typename?: "CustomerCampaign";
  addedByUserId: Scalars["Int"];
  amount: Scalars["Float"];
  campaignCategoryId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  name: Scalars["String"];
  pausedAt?: Maybe<Scalars["DateTime"]>;
  startsAt: Scalars["DateTime"];
};

export type CustomerCampaignBillingConfig = {
  __typename?: "CustomerCampaignBillingConfig";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  isAutoPayEnabled: Scalars["Boolean"];
};

export type CustomerCampaignConnection = {
  __typename?: "CustomerCampaignConnection";
  nodes: Array<CustomerCampaign>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoice = {
  __typename?: "CustomerCampaignInvoice";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: CustomerCampaignInvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerCampaignInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceCharge = {
  __typename?: "CustomerCampaignInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaign: CustomerCampaign;
  customerCampaignId: Scalars["Int"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  monthIndex: Scalars["Int"];
  status: CustomerCampaignInvoiceChargeStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceChargeStatus = {
  __typename?: "CustomerCampaignInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerCampaignInvoiceConnection = {
  __typename?: "CustomerCampaignInvoiceConnection";
  nodes: Array<CustomerCampaignInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoiceData = {
  __typename?: "CustomerCampaignInvoiceData";
  invoiceCharges: Array<CustomerCampaignInvoiceCharge>;
  invoicePayments: Array<CustomerCampaignInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
};

export type CustomerCampaignInvoicePayment = {
  __typename?: "CustomerCampaignInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerCampaignInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoicePaymentStatus = {
  __typename?: "CustomerCampaignInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum CustomerCampaignInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerCampaignInvoiceStatus = {
  __typename?: "CustomerCampaignInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerConnection = {
  __typename?: "CustomerConnection";
  nodes: Array<Customer>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerHoursOfOperationItem = {
  __typename?: "CustomerHoursOfOperationItem";
  closeAt: Scalars["String"];
  customerId: Scalars["Int"];
  dayIndex: Scalars["Int"];
  id: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CustomerInvoice = {
  __typename?: "CustomerInvoice";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: InvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoiceCharge = {
  __typename?: "CustomerInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  status: CustomerInvoiceChargeStatus;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type CustomerInvoiceChargeStatus = {
  __typename?: "CustomerInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceConnection = {
  __typename?: "CustomerInvoiceConnection";
  nodes: Array<CustomerInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerInvoicePayment = {
  __typename?: "CustomerInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoicePaymentStatus = {
  __typename?: "CustomerInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceRefund = {
  __typename?: "CustomerInvoiceRefund";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  customerInvoicePaymentId: Scalars["Int"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
  statusId: Scalars["String"];
};

export enum CustomerInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerInvoiceStatus = {
  __typename?: "CustomerInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerKeyword = {
  __typename?: "CustomerKeyword";
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerKeywordRanking?: Maybe<CustomerKeywordRanking>;
  customerKeywordRanks: CustomerKeywordRankingConnection;
  displayName: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  latestRank?: Maybe<CustomerKeywordRanking>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  startRank?: Maybe<CustomerKeywordRanking>;
};

export type CustomerKeywordCustomerKeywordRankingArgs = {
  daysAgo: Scalars["Int"];
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerKeywordCustomerKeywordRanksArgs = {
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchEngine: Scalars["String"];
  sort?: InputMaybe<CustomerKeywordRankingSort>;
};

export type CustomerKeywordLatestRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordStartRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordConnection = {
  __typename?: "CustomerKeywordConnection";
  nodes: Array<CustomerKeyword>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerKeywordRanking = {
  __typename?: "CustomerKeywordRanking";
  createdAt: Scalars["DateTime"];
  customerKeyword: CustomerKeyword;
  id: Scalars["Int"];
  rank: Scalars["Int"];
  searchEngine: Scalars["String"];
};

export type CustomerKeywordRankingConnection = {
  __typename?: "CustomerKeywordRankingConnection";
  nodes: Array<CustomerKeywordRanking>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerKeywordRankingSort {
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerPaymentMethod = {
  __typename?: "CustomerPaymentMethod";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerPaymentMethodCard: CustomerPaymentMethodCard;
  externalId: Scalars["String"];
  externalProvider: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  type: Scalars["String"];
};

export type CustomerPaymentMethodCard = {
  __typename?: "CustomerPaymentMethodCard";
  brand: Scalars["String"];
  brandDisplayName: Scalars["String"];
  expirationMonth: Scalars["Int"];
  expirationYear: Scalars["Int"];
  last4Digits: Scalars["String"];
};

export type CustomerPaymentMethodConnection = {
  __typename?: "CustomerPaymentMethodConnection";
  nodes: Array<CustomerPaymentMethod>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerPremiumBusinessListingSpec = {
  __typename?: "CustomerPremiumBusinessListingSpec";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  hotelAmenities: Array<Scalars["String"]>;
  hotelBathroom?: Maybe<Scalars["String"]>;
  hotelFrontDesk?: Maybe<Scalars["String"]>;
  hotelHousekeeping?: Maybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: Maybe<Scalars["String"]>;
  hotelMinimumStay?: Maybe<Scalars["String"]>;
  hotelOnSiteStaff?: Maybe<Scalars["Boolean"]>;
  hotelSecurity?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAlwaysOpen?: Maybe<Scalars["Boolean"]>;
  isBlackOwned?: Maybe<Scalars["Boolean"]>;
  isWomenOwned?: Maybe<Scalars["Boolean"]>;
  priceRangeLevel?: Maybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: Maybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: Maybe<Scalars["String"]>;
  yearsInBusiness?: Maybe<Scalars["Int"]>;
};

export type CustomerPremiumBusinessListingSpecImage = {
  __typename?: "CustomerPremiumBusinessListingSpecImage";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  file: File;
  fileId: Scalars["String"];
  id: Scalars["Int"];
  imageTypeId: Scalars["String"];
};

export type CustomerPremiumBusinessListingSpecImageConnection = {
  __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
  nodes: Array<CustomerPremiumBusinessListingSpecImage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerProposal = {
  __typename?: "CustomerProposal";
  id: Scalars["Int"];
  orderContentStrategies: Array<OrderContentStrategy>;
  proposedOrderTopics: Array<ProposedOrderTopic>;
  summary?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type CustomerProposalOrderContentStrategiesArgs = {
  sort?: InputMaybe<OrderContentStrategiesSort>;
};

export type CustomerQuestionnaire = {
  __typename?: "CustomerQuestionnaire";
  businessDescription?: Maybe<Scalars["String"]>;
  callToActionUrls?: Maybe<Scalars["String"]>;
  competitorWebsites?: Maybe<Scalars["String"]>;
  contentPreference?: Maybe<Scalars["String"]>;
  contentPublishingPermissions?: Maybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  customerMarketSize?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAddImage?: Maybe<Scalars["Boolean"]>;
  preferredVoice?: Maybe<Scalars["String"]>;
  productsOffered?: Maybe<Scalars["String"]>;
  purposeOfContent?: Maybe<Scalars["String"]>;
  serviceArea?: Maybe<Scalars["String"]>;
  targetArea?: Maybe<Scalars["String"]>;
  targetAudience?: Maybe<Scalars["String"]>;
  targetInternalLinks?: Maybe<Scalars["String"]>;
  targetKeywords?: Maybe<Scalars["String"]>;
  websiteCms?: Maybe<Scalars["String"]>;
  websiteHosting?: Maybe<Scalars["String"]>;
  whoCanChangeWebsite?: Maybe<Scalars["String"]>;
};

export type CustomerStatus = {
  __typename?: "CustomerStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerUser = {
  __typename?: "CustomerUser";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  userId: Scalars["Int"];
};

export enum CustomersKeywordSort {
  CurrentRankAsc = "CURRENT_RANK_ASC",
  CurrentRankDesc = "CURRENT_RANK_DESC",
  IsPrimaryAsc = "IS_PRIMARY_ASC",
  IsPrimaryDesc = "IS_PRIMARY_DESC",
  LatestRankAsc = "LATEST_RANK_ASC",
  LatestRankDesc = "LATEST_RANK_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type DateQuery = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  orIsNull?: InputMaybe<Scalars["Boolean"]>;
};

export type DeleteCustomerBillingContactInput = {
  customerBillingContactId: Scalars["Int"];
};

export type DeleteCustomerBillingContactResponse = {
  __typename?: "DeleteCustomerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerPaymentMethodInput = {
  customerPaymentMethodId: Scalars["Int"];
};

export type DeleteCustomerPaymentMethodResponse = {
  __typename?: "DeleteCustomerPaymentMethodResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageInput = {
  customerPremiumBusinessListingSpecImageId: Scalars["Int"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "DeleteCustomerPremiumBusinessListingSpecImageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type EnableAutoPayInformation = {
  __typename?: "EnableAutoPayInformation";
  dueImmediatelyAmount?: Maybe<Scalars["Float"]>;
  nextBillingAmount?: Maybe<Scalars["Float"]>;
  nextBillingDate?: Maybe<Scalars["DateTime"]>;
};

export type EnableAutopayInformation = {
  __typename?: "EnableAutopayInformation";
  nextBillingAmount?: Maybe<Scalars["Float"]>;
  nextBillingDate?: Maybe<Scalars["DateTime"]>;
};

export type FeedbackContentItem = {
  __typename?: "FeedbackContentItem";
  contentItemId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  feedback: Scalars["String"];
  id: Scalars["Int"];
  reviewerUserId: Scalars["Int"];
};

export type File = {
  __typename?: "File";
  contentType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
  url: Scalars["String"];
};

export type GbpItemDelivery = {
  __typename?: "GbpItemDelivery";
  content: Scalars["String"];
  contentOnlyText: Scalars["String"];
  partnerLogoUrl?: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  publicUrl: Scalars["String"];
  readMoreUrl?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export type GenerateUrlForFileUploadInput = {
  contentType: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
};

export type GenerateUrlForFileUploadResponse = {
  __typename?: "GenerateUrlForFileUploadResponse";
  expiresAt: Scalars["DateTime"];
  fileId: Scalars["String"];
  url: Scalars["String"];
};

export type GoogleAccount = {
  __typename?: "GoogleAccount";
  accountName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  verificationState?: Maybe<Scalars["String"]>;
  vettedState?: Maybe<Scalars["String"]>;
};

export type GoogleAccountsResponse = {
  __typename?: "GoogleAccountsResponse";
  accounts?: Maybe<Array<GoogleAccount>>;
};

export type GoogleLocation = {
  __typename?: "GoogleLocation";
  name?: Maybe<Scalars["String"]>;
  storeCode?: Maybe<Scalars["String"]>;
  storefrontAddress?: Maybe<PostalAddress>;
  title?: Maybe<Scalars["String"]>;
  websiteUri?: Maybe<Scalars["String"]>;
};

export type GoogleLocationsResponse = {
  __typename?: "GoogleLocationsResponse";
  locations?: Maybe<Array<GoogleLocation>>;
};

export type GoogleReview = {
  __typename?: "GoogleReview";
  comment?: Maybe<Scalars["String"]>;
  createTime?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  reviewId?: Maybe<Scalars["String"]>;
  reviewReply?: Maybe<ReviewReply>;
  reviewer?: Maybe<Reviewer>;
  starRating?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
};

export type GoogleReviewReplyInput = {
  account?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  reviewId?: InputMaybe<Scalars["String"]>;
};

export type GoogleReviewReplyResponse = {
  __typename?: "GoogleReviewReplyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type GoogleReviewRespose = {
  __typename?: "GoogleReviewRespose";
  averageRating?: Maybe<Scalars["Float"]>;
  nextPageToken?: Maybe<Scalars["String"]>;
  reviews?: Maybe<Array<GoogleReview>>;
  totalReviewCount?: Maybe<Scalars["Int"]>;
};

export type InputFieldError = {
  __typename?: "InputFieldError";
  fieldName: Scalars["String"];
  message: Scalars["String"];
};

export type InvoiceData = {
  __typename?: "InvoiceData";
  invoiceCharges: Array<CustomerInvoiceCharge>;
  invoicePayments: Array<CustomerInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
  totalRefundsAmount: Scalars["Float"];
};

export type KeywordReview = {
  __typename?: "KeywordReview";
  id: Scalars["Int"];
  proposedCustomerKeywords: Array<ProposedCustomerKeyword>;
  summary?: Maybe<Scalars["String"]>;
};

export type KeywordReviewProposedCustomerKeywordsArgs = {
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isRejected?: InputMaybe<Scalars["Boolean"]>;
};

export type LinkLocation = {
  __typename?: "LinkLocation";
  authorityScore?: Maybe<Scalars["Int"]>;
  domain: Scalars["String"];
  domainAuthority: Scalars["Int"];
  id: Scalars["Int"];
  monthlyTraffic?: Maybe<Scalars["Int"]>;
  spam?: Maybe<Scalars["Int"]>;
};

export type LoginGoogleCallbackInput = {
  code: Scalars["String"];
};

export type LoginGoogleCallbackResponse = {
  __typename?: "LoginGoogleCallbackResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type LoginGoogleResponse = {
  __typename?: "LoginGoogleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type LoginUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  googleAccessToken?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  ssoCode?: InputMaybe<Scalars["String"]>;
};

export type LoginUserResponse = {
  __typename?: "LoginUserResponse";
  isLoggedIn?: Maybe<Scalars["Boolean"]>;
  userId?: Maybe<Scalars["Int"]>;
};

export type LogoutUserResponse = {
  __typename?: "LogoutUserResponse";
  isLoggedOut: Scalars["Boolean"];
};

export type Message = {
  __typename?: "Message";
  content: Scalars["String"];
  conversation: Conversation;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isFromCustomer: Scalars["Boolean"];
  user: User;
};

export type MessageConnection = {
  __typename?: "MessageConnection";
  nodes: Array<Message>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum MessageSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export type Mutation = {
  __typename?: "Mutation";
  createConversation: CreateConversationResponse;
  createCustomer: CreateCustomerResponse;
  createCustomerBillingContact: CreateCustomerBillingContactResponse;
  createCustomerCampaign: CreateCustomerCampaignResponse;
  createCustomerPaymentMethodSetupIntent: CreateCustomerPaymentMethodSetupIntentResponse;
  createCustomerPremiumBusinessListingSpecImage: CreateCustomerPremiumBusinessListingSpecImageResponse;
  createMessage: CreateMessageResponse;
  createUserInteractionEvent: CreateUserInteractionEventResponse;
  deleteCustomerBillingContact: DeleteCustomerBillingContactResponse;
  deleteCustomerPaymentMethod: DeleteCustomerPaymentMethodResponse;
  deleteCustomerPremiumBusinessListingSpecImage: DeleteCustomerPremiumBusinessListingSpecImageResponse;
  generateUrlForFileUpload: GenerateUrlForFileUploadResponse;
  googleReviewReply: GoogleReviewReplyResponse;
  loginGoogleCallback: LoginGoogleCallbackResponse;
  loginGoogleService: LoginGoogleResponse;
  loginUser: LoginUserResponse;
  logoutUser: LogoutUserResponse;
  payCustomerCampaignInvoice: PayCustomerCampaignInvoiceResponse;
  payCustomerInvoice: PayCustomerInvoiceResponse;
  requestPasswordReset: RequestPasswordResetResponse;
  resetPassword: ResetPasswordResponse;
  saveContentStrategyReview: SaveContentStrategyReviewResponse;
  saveCustomerBillingConfig: SaveCustomerBillingConfigResponse;
  saveCustomerBusinessListingSpec: SaveCustomerBusinessListingSpecResponse;
  saveCustomerCampaignBillingConfig: SaveCustomerCampaignBillingConfigResponse;
  saveCustomerHoursOfOperationItems: SaveCustomerHoursOfOperationItemsResponse;
  saveCustomerPremiumBusinessListingSpec: SaveCustomerPremiumBusinessListingSpecResponse;
  saveCustomerQuestionnaire: SaveCustomerQuestionnaireResponse;
  saveReviewTaskContentItem: SaveReviewTaskContentItemResponse;
  submitContentCalendarReview: SubmitContentCalendarReviewResponse;
  submitContentReview: SubmitContentReviewResponse;
  submitKeywordReview: SubmitKeywordReviewInputResponse;
  updateCustomer: UpdateCustomerResponse;
  updateUser: UpdateUserResponse;
  version?: Maybe<Scalars["String"]>;
};

export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateCustomerBillingContactArgs = {
  input: CreateCustomerBillingContactInput;
};

export type MutationCreateCustomerCampaignArgs = {
  input: CreateCustomerCampaignInput;
};

export type MutationCreateCustomerPaymentMethodSetupIntentArgs = {
  input: CreateCustomerPaymentMethodSetupIntentInput;
};

export type MutationCreateCustomerPremiumBusinessListingSpecImageArgs = {
  input: CreateCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreateUserInteractionEventArgs = {
  input: CreateUserInteractionEventInput;
};

export type MutationDeleteCustomerBillingContactArgs = {
  input: DeleteCustomerBillingContactInput;
};

export type MutationDeleteCustomerPaymentMethodArgs = {
  input: DeleteCustomerPaymentMethodInput;
};

export type MutationDeleteCustomerPremiumBusinessListingSpecImageArgs = {
  input: DeleteCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationGenerateUrlForFileUploadArgs = {
  input?: InputMaybe<GenerateUrlForFileUploadInput>;
};

export type MutationGoogleReviewReplyArgs = {
  input: GoogleReviewReplyInput;
};

export type MutationLoginGoogleCallbackArgs = {
  input?: InputMaybe<LoginGoogleCallbackInput>;
};

export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

export type MutationPayCustomerCampaignInvoiceArgs = {
  input: PayCustomerCampaignInvoiceInput;
};

export type MutationPayCustomerInvoiceArgs = {
  input: PayCustomerInvoiceInput;
};

export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSaveContentStrategyReviewArgs = {
  input: SaveContentStrategyReviewInput;
};

export type MutationSaveCustomerBillingConfigArgs = {
  input: SaveCustomerBillingConfigInput;
};

export type MutationSaveCustomerBusinessListingSpecArgs = {
  input: SaveCustomerBusinessListingSpecInput;
};

export type MutationSaveCustomerCampaignBillingConfigArgs = {
  input: SaveCustomerCampaignBillingConfigInput;
};

export type MutationSaveCustomerHoursOfOperationItemsArgs = {
  input: SaveCustomerHoursOfOperationItemsInput;
};

export type MutationSaveCustomerPremiumBusinessListingSpecArgs = {
  input: SaveCustomerPremiumBusinessListingSpecInput;
};

export type MutationSaveCustomerQuestionnaireArgs = {
  input: SaveCustomerQuestionnaireInput;
};

export type MutationSaveReviewTaskContentItemArgs = {
  input: SaveReviewTaskContentItemInput;
};

export type MutationSubmitContentCalendarReviewArgs = {
  input: SubmitContentCalendarReviewInput;
};

export type MutationSubmitContentReviewArgs = {
  input: SubmitContentReviewInput;
};

export type MutationSubmitKeywordReviewArgs = {
  input: SubmitKeywordReviewInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Order = {
  __typename?: "Order";
  canDelete: Scalars["Boolean"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  customerBillingStatus: OrderBillingStatus;
  customerId: Scalars["Int"];
  customerKeyword?: Maybe<CustomerKeyword>;
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  linkLocation?: Maybe<LinkLocation>;
  orderIssues: Array<OrderIssue>;
  partnerProduct: PartnerProduct;
  quantity: Scalars["Int"];
  resultUrl?: Maybe<Scalars["String"]>;
  resultUrlDomainAuthority?: Maybe<Scalars["Int"]>;
  resultUrlTraffic?: Maybe<Scalars["Int"]>;
  startDate: Scalars["DateTime"];
  status: OrderStatus;
  topic?: Maybe<Scalars["String"]>;
  totalRetailAmount: Scalars["Float"];
  workType: Scalars["String"];
};

export type OrderBillingStatus = {
  __typename?: "OrderBillingStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrderConnection = {
  __typename?: "OrderConnection";
  nodes: Array<Order>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type OrderContentItem = {
  __typename?: "OrderContentItem";
  contentItemDelivery: ContentItemDelivery;
  id: Scalars["Int"];
};

export enum OrderContentStrategiesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  OrderStartDateAsc = "ORDER_START_DATE_ASC",
  OrderStartDateDesc = "ORDER_START_DATE_DESC",
}

export type OrderContentStrategy = {
  __typename?: "OrderContentStrategy";
  createdAt: Scalars["DateTime"];
  customerKeyword?: Maybe<CustomerKeyword>;
  id: Scalars["Int"];
  order?: Maybe<Order>;
  topic?: Maybe<Scalars["String"]>;
};

export type OrderGbpItem = {
  __typename?: "OrderGbpItem";
  gbpItemDelivery: GbpItemDelivery;
  id: Scalars["Int"];
};

export type OrderIssue = {
  __typename?: "OrderIssue";
  message: Scalars["String"];
  title: Scalars["String"];
};

export type OrderStatus = {
  __typename?: "OrderStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum OrdersSort {
  CompletedAtDesc = "COMPLETED_AT_DESC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
}

export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
};

export type Partner = {
  __typename?: "Partner";
  emailLogoUrl?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isBillingEnforced: Scalars["Boolean"];
  name: Scalars["String"];
  reportingLogoUrl?: Maybe<Scalars["String"]>;
  stripeAccountId?: Maybe<Scalars["String"]>;
};

export type PartnerPackage = {
  __typename?: "PartnerPackage";
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  monthlySpend: Scalars["Float"];
  months: Scalars["Int"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type PartnerPackageConnection = {
  __typename?: "PartnerPackageConnection";
  nodes: Array<PartnerPackage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerPackageSort {
  MonthlySpendAsc = "MONTHLY_SPEND_ASC",
  MonthlySpendDesc = "MONTHLY_SPEND_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PartnerProduct = {
  __typename?: "PartnerProduct";
  id: Scalars["Int"];
  name: Scalars["String"];
  partnerProductPrices: PartnerProductPriceConnection;
  product: Product;
  productId: Scalars["String"];
};

export type PartnerProductPartnerProductPricesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerProductPriceSort>;
  startsAt?: InputMaybe<DateQuery>;
};

export type PartnerProductConnection = {
  __typename?: "PartnerProductConnection";
  nodes: Array<PartnerProduct>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerProductPrice = {
  __typename?: "PartnerProductPrice";
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  partnerProductId: Scalars["Int"];
  price: Scalars["Float"];
  startsAt: Scalars["DateTime"];
};

export type PartnerProductPriceConnection = {
  __typename?: "PartnerProductPriceConnection";
  nodes: Array<PartnerProductPrice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerProductPriceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartsAtAsc = "STARTS_AT_ASC",
  StartsAtDesc = "STARTS_AT_DESC",
}

export type PayCustomerCampaignInvoiceInput = {
  customerCampaignInvoiceId: Scalars["Int"];
  customerPaymentMethodId: Scalars["Int"];
};

export type PayCustomerCampaignInvoiceResponse = {
  __typename?: "PayCustomerCampaignInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type PayCustomerInvoiceInput = {
  customerInvoiceId: Scalars["Int"];
  customerPaymentMethodId: Scalars["Int"];
};

export type PayCustomerInvoiceResponse = {
  __typename?: "PayCustomerInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type PostalAddress = {
  __typename?: "PostalAddress";
  addressLines?: Maybe<Array<Scalars["String"]>>;
  administrativeArea?: Maybe<Scalars["String"]>;
  languageCode?: Maybe<Scalars["String"]>;
  locality?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  regionCode?: Maybe<Scalars["String"]>;
  revision?: Maybe<Scalars["Int"]>;
  sortingCode?: Maybe<Scalars["String"]>;
  sublocality?: Maybe<Scalars["String"]>;
};

export type Product = {
  __typename?: "Product";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isContentStrategyRequired: Scalars["Boolean"];
  isSystemOrdered: Scalars["Boolean"];
  maxOrderQuantity: Scalars["Int"];
  name: Scalars["String"];
};

export type ProposedCustomerKeyword = {
  __typename?: "ProposedCustomerKeyword";
  country?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ProposedOrderTopic = {
  __typename?: "ProposedOrderTopic";
  customerKeyword: CustomerKeyword;
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  rejectionComment?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  amTasks: AmTaskConnection;
  categories: CategoryConnection;
  conversation?: Maybe<Conversation>;
  conversations: ConversationConnection;
  customer?: Maybe<Customer>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerCampaignInvoice?: Maybe<CustomerCampaignInvoice>;
  customerInvoice?: Maybe<CustomerInvoice>;
  customerKeywordRankings: CustomerKeywordRankingConnection;
  customerKeywords: CustomerKeywordConnection;
  customers: CustomerConnection;
  deliverableFile?: Maybe<File>;
  geminiReplyComment: ReplyComment;
  getGoogleAccounts: GoogleAccountsResponse;
  getGoogleLocations: GoogleLocationsResponse;
  getGoogleReviews: GoogleReviewRespose;
  order?: Maybe<Order>;
  orderContentItem?: Maybe<OrderContentItem>;
  orderGbpItem?: Maybe<OrderGbpItem>;
  orders: OrderConnection;
  partnerPackages: PartnerPackageConnection;
  partnerProducts: PartnerProductConnection;
  pendingTasks: Array<Task>;
  task?: Maybe<Task>;
  user?: Maybe<User>;
  version?: Maybe<Scalars["String"]>;
  viewer: Viewer;
};

export type QueryAmTasksArgs = {
  completedAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCategoriesArgs = {
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CategoriesSort>;
};

export type QueryConversationArgs = {
  conversationId: Scalars["String"];
};

export type QueryConversationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  wasStartedByCustomer?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryCustomerBusinessListingSpecArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerCampaignInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerKeywordRankingsArgs = {
  createdAt?: InputMaybe<DateQuery>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchEngine?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerKeywordRankingSort>;
};

export type QueryCustomerKeywordsArgs = {
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersKeywordSort>;
};

export type QueryCustomersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryDeliverableFileArgs = {
  fileId: Scalars["String"];
  orderId: Scalars["Int"];
};

export type QueryGeminiReplyCommentArgs = {
  comment: Scalars["String"];
  keywords: Scalars["String"];
};

export type QueryGetGoogleAccountsArgs = {
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleLocationsArgs = {
  account: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleReviewsArgs = {
  account: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  pageToken?: InputMaybe<Scalars["String"]>;
};

export type QueryOrderArgs = {
  id: Scalars["Int"];
};

export type QueryOrderContentItemArgs = {
  publicId: Scalars["String"];
};

export type QueryOrderGbpItemArgs = {
  publicId: Scalars["String"];
};

export type QueryOrdersArgs = {
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
  startDate?: InputMaybe<DateQuery>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPartnerPackagesArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  notInSearch?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search1?: InputMaybe<Scalars["String"]>;
  search2?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PartnerPackageSort>;
};

export type QueryPartnerProductsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryTaskArgs = {
  taskId: Scalars["Int"];
};

export type QueryUserArgs = {
  id: Scalars["Int"];
};

export type RejectedKeyword = {
  proposedCustomerKeywordId: Scalars["Int"];
  reason: Scalars["String"];
};

export type RejectedTopic = {
  proposedOrderTopicId: Scalars["Int"];
  rejectionComment: Scalars["String"];
};

export type ReplyComment = {
  __typename?: "ReplyComment";
  reply: Scalars["String"];
};

export type RequestPasswordResetInput = {
  email: Scalars["String"];
  origin?: InputMaybe<Scalars["String"]>;
};

export type RequestPasswordResetResponse = {
  __typename?: "RequestPasswordResetResponse";
  wasRequested: Scalars["Boolean"];
};

export type ResetPasswordInput = {
  id: Scalars["String"];
  password: Scalars["String"];
};

export type ResetPasswordResponse = {
  __typename?: "ResetPasswordResponse";
  wasReset: Scalars["Boolean"];
};

export type ResponseError = {
  __typename?: "ResponseError";
  inputFieldErrors: Array<InputFieldError>;
  message: Scalars["String"];
};

export type ReviewReply = {
  __typename?: "ReviewReply";
  comment?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
};

export type Reviewer = {
  __typename?: "Reviewer";
  displayName?: Maybe<Scalars["String"]>;
  isAnonymous?: Maybe<Scalars["Boolean"]>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
};

export type SaveContentStrategyReviewInput = {
  revisionRequests: Array<ContentStrategyRevisionRequest>;
  taskId: Scalars["Int"];
};

export type SaveContentStrategyReviewResponse = {
  __typename?: "SaveContentStrategyReviewResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveCustomerBillingConfigInput = {
  autoPayCustomerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  customerId: Scalars["Int"];
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveCustomerBillingConfigResponse = {
  __typename?: "SaveCustomerBillingConfigResponse";
  customerBillingConfig: CustomerBillingConfig;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerBusinessListingSpecResponse = {
  __typename?: "SaveCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec: CustomerBusinessListingSpec;
};

export type SaveCustomerCampaignBillingConfigInput = {
  autoPayCustomerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  customerId: Scalars["Int"];
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveCustomerCampaignBillingConfigResponse = {
  __typename?: "SaveCustomerCampaignBillingConfigResponse";
  customerCampaignBillingConfig: CustomerCampaignBillingConfig;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerHoursOfOperationItemsInput = {
  itemsToCreate: Array<CreateCustomerHoursOfOperationItemInput>;
  itemsToDelete: Array<Scalars["Int"]>;
  itemsToUpdate: Array<UpdateCustomerHoursOfOperationItemInput>;
};

export type SaveCustomerHoursOfOperationItemsResponse = {
  __typename?: "SaveCustomerHoursOfOperationItemsResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveCustomerPremiumBusinessListingSpecInput = {
  hotelAmenities?: InputMaybe<Array<Scalars["String"]>>;
  hotelBathroom?: InputMaybe<Scalars["String"]>;
  hotelFrontDesk?: InputMaybe<Scalars["String"]>;
  hotelHousekeeping?: InputMaybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: InputMaybe<Scalars["String"]>;
  hotelMinimumStay?: InputMaybe<Scalars["String"]>;
  hotelOnSiteStaff?: InputMaybe<Scalars["Boolean"]>;
  hotelSecurity?: InputMaybe<Scalars["String"]>;
  isAlwaysOpen?: InputMaybe<Scalars["Boolean"]>;
  isBlackOwned?: InputMaybe<Scalars["Boolean"]>;
  isWomenOwned?: InputMaybe<Scalars["Boolean"]>;
  priceRangeLevel?: InputMaybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: InputMaybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: InputMaybe<Scalars["String"]>;
  yearsInBusiness?: InputMaybe<Scalars["Int"]>;
};

export type SaveCustomerPremiumBusinessListingSpecResponse = {
  __typename?: "SaveCustomerPremiumBusinessListingSpecResponse";
  customerPremiumBusinessListingSpec: CustomerPremiumBusinessListingSpec;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerQuestionnaireInput = {
  businessDescription?: InputMaybe<Scalars["String"]>;
  callToActionUrls?: InputMaybe<Scalars["String"]>;
  competitorWebsites?: InputMaybe<Scalars["String"]>;
  contentPreference?: InputMaybe<Scalars["String"]>;
  contentPublishingPermissions?: InputMaybe<Scalars["String"]>;
  customerMarketSize?: InputMaybe<Scalars["String"]>;
  isAddImage?: InputMaybe<Scalars["Boolean"]>;
  preferredVoice?: InputMaybe<Scalars["String"]>;
  productsOffered?: InputMaybe<Scalars["String"]>;
  purposeOfContent?: InputMaybe<Scalars["String"]>;
  serviceArea?: InputMaybe<Scalars["String"]>;
  targetArea?: InputMaybe<Scalars["String"]>;
  targetAudience?: InputMaybe<Scalars["String"]>;
  targetInternalLinks?: InputMaybe<Scalars["String"]>;
  targetKeywords?: InputMaybe<Scalars["String"]>;
  websiteCms?: InputMaybe<Scalars["String"]>;
  websiteHosting?: InputMaybe<Scalars["String"]>;
  whoCanChangeWebsite?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerQuestionnaireResponse = {
  __typename?: "SaveCustomerQuestionnaireResponse";
  customerQuestionnaire: CustomerQuestionnaire;
};

export type SaveReviewTaskContentItemInput = {
  content: Scalars["String"];
  taskId: Scalars["Int"];
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SaveReviewTaskContentItemResponse = {
  __typename?: "SaveReviewTaskContentItemResponse";
  contentItem: ContentItem;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  task: Task;
};

export type SubmitContentCalendarReviewInput = {
  customerProposalId: Scalars["Int"];
  rejectedTopics: Array<RejectedTopic>;
  taskId: Scalars["Int"];
};

export type SubmitContentCalendarReviewResponse = {
  __typename?: "SubmitContentCalendarReviewResponse";
  task?: Maybe<Task>;
};

export type SubmitContentReviewInput = {
  isApproved: Scalars["Boolean"];
  rejectionComments?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
};

export type SubmitContentReviewResponse = {
  __typename?: "SubmitContentReviewResponse";
  task?: Maybe<Task>;
};

export type SubmitKeywordReviewInput = {
  customerKeywordProposalId: Scalars["Int"];
  rejectedKeywords: Array<RejectedKeyword>;
  taskId: Scalars["Int"];
};

export type SubmitKeywordReviewInputResponse = {
  __typename?: "SubmitKeywordReviewInputResponse";
  done: Scalars["Boolean"];
};

export type Task = {
  __typename?: "Task";
  contentItem?: Maybe<ContentItem>;
  customerProposal?: Maybe<CustomerProposal>;
  id: Scalars["Int"];
  keywordReview?: Maybe<KeywordReview>;
  partnerProduct: PartnerProduct;
  status: Scalars["String"];
  taskType: TaskType;
  taskTypeId: Scalars["String"];
};

export type TaskType = {
  __typename?: "TaskType";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type UberallListingDetails = {
  __typename?: "UberallListingDetails";
  action?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  listingUrl?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  typeName?: Maybe<Scalars["String"]>;
};

export type UberallListingDetailsResponse = {
  __typename?: "UberallListingDetailsResponse";
  countListingsActionRequired?: Maybe<Scalars["Int"]>;
  countListingsBeingUpdated?: Maybe<Scalars["Int"]>;
  countListingsInSync?: Maybe<Scalars["Int"]>;
  countListingsLinked?: Maybe<Scalars["Int"]>;
  countListingsSubmitted?: Maybe<Scalars["Int"]>;
  listings?: Maybe<Array<UberallListingDetails>>;
  totalListings?: Maybe<Scalars["Int"]>;
};

export type UpdateCustomerHoursOfOperationItemInput = {
  closeAt?: InputMaybe<Scalars["String"]>;
  dayIndex?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  isOpen?: InputMaybe<Scalars["Boolean"]>;
  openAt?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerInput = {
  address?: InputMaybe<Scalars["String"]>;
  autoPayCustomerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  gbpUrl?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerResponse = {
  __typename?: "UpdateCustomerResponse";
  customer?: Maybe<Customer>;
};

export type UpdateUserInput = {
  emailPreference?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type UpdateUserResponse = {
  __typename?: "UpdateUserResponse";
  user?: Maybe<User>;
};

export type User = {
  __typename?: "User";
  calendarUrl?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  emailPreference?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["Int"];
  isTracking: Scalars["Boolean"];
  lastName: Scalars["String"];
  profilePictureFile?: Maybe<File>;
  title?: Maybe<Scalars["String"]>;
};

export type Viewer = {
  __typename?: "Viewer";
  customerUser?: Maybe<CustomerUser>;
  customers: CustomerConnection;
  isAdmin: Scalars["Boolean"];
  user?: Maybe<User>;
};

export type ActionItemsBarQueryVariables = Exact<{ [key: string]: never }>;

export type ActionItemsBarQuery = {
  __typename?: "Query";
  pendingTasks: Array<{
    __typename?: "Task";
    id: number;
    taskType: { __typename?: "TaskType"; id: string; name: string };
  }>;
};

export type CategoriesForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type CategoriesForSelectorQuery = {
  __typename?: "Query";
  categories: {
    __typename?: "CategoryConnection";
    nodes: Array<{ __typename?: "Category"; id: number; name: string }>;
  };
};

export type ClientLocationSelectorQueryVariables = Exact<{
  isListed?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ClientLocationSelectorQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerBusinessListingSpecs: {
      __typename?: "CustomerBusinessListingSpecConnection";
      nodes: Array<{ __typename?: "CustomerBusinessListingSpec"; id: number; address: string; city: string }>;
    };
  } | null;
};

export type UploadFileDialog_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadInput;
}>;

export type UploadFileDialog_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUpload: { __typename?: "GenerateUrlForFileUploadResponse"; url: string; fileId: string };
};

export type MainMenu_CurrentCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type MainMenu_CurrentCustomerQuery = {
  __typename?: "Query";
  customer?: { __typename?: "Customer"; id: number; name: string } | null;
};

export type MainMenuPartnerLogoQueryVariables = Exact<{ [key: string]: never }>;

export type MainMenuPartnerLogoQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    analyticsReportingInlineFrameUrl?: string | null;
    customerBusinessListingSpecs: {
      __typename?: "CustomerBusinessListingSpecConnection";
      nodes: Array<{ __typename?: "CustomerBusinessListingSpec"; isReviewEnabled: boolean }>;
    };
    partner: { __typename?: "Partner"; id: number; name: string; reportingLogoUrl?: string | null };
  } | null;
};

export type AuthManager_ViewerFragment = {
  __typename?: "Viewer";
  isAdmin: boolean;
  user?: { __typename?: "User"; id: number; fullName: string } | null;
};

export type AuthManager_ViewerUserFragment = { __typename?: "User"; id: number; fullName: string };

export type AuthManager_CustomerFragment = {
  __typename?: "Customer";
  id: number;
  name: string;
  accountManagerUser?: {
    __typename?: "User";
    id: number;
    calendarUrl?: string | null;
    title?: string | null;
    fullName: string;
    profilePictureFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
  } | null;
};

export type AuthManagerQueryVariables = Exact<{ [key: string]: never }>;

export type AuthManagerQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    isAdmin: boolean;
    user?: { __typename?: "User"; id: number; fullName: string } | null;
  };
  customer?: {
    __typename?: "Customer";
    id: number;
    name: string;
    accountManagerUser?: {
      __typename?: "User";
      id: number;
      calendarUrl?: string | null;
      title?: string | null;
      fullName: string;
      profilePictureFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
    } | null;
  } | null;
};

export type LogoutUserMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutUserMutation = {
  __typename?: "Mutation";
  logoutUser: { __typename?: "LogoutUserResponse"; isLoggedOut: boolean };
};

export type CustomerAccountQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerAccountQuery = {
  __typename?: "Query";
  customer?: { __typename?: "Customer"; id: number; name: string; website: string; categoryId?: number | null } | null;
};

export type CreateCustomerPremiumBusinessListingSpecImageMutationVariables = Exact<{
  input: CreateCustomerPremiumBusinessListingSpecImageInput;
}>;

export type CreateCustomerPremiumBusinessListingSpecImageMutation = {
  __typename?: "Mutation";
  createCustomerPremiumBusinessListingSpecImage: {
    __typename?: "CreateCustomerPremiumBusinessListingSpecImageResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    customerPremiumBusinessListingSpecImage: {
      __typename?: "CustomerPremiumBusinessListingSpecImage";
      id: number;
      customerId: number;
      imageTypeId: string;
      file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
    };
  };
};

export type DeleteCustomerPremiumBusinessListingSpecImageMutationVariables = Exact<{
  input: DeleteCustomerPremiumBusinessListingSpecImageInput;
}>;

export type DeleteCustomerPremiumBusinessListingSpecImageMutation = {
  __typename?: "Mutation";
  deleteCustomerPremiumBusinessListingSpecImage: {
    __typename?: "DeleteCustomerPremiumBusinessListingSpecImageResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type CustomerDetailsCompanyImagesQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerDetailsCompanyImagesQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    hasPremiumListing: boolean;
    logoImage: {
      __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
      nodes: Array<{
        __typename?: "CustomerPremiumBusinessListingSpecImage";
        id: number;
        customerId: number;
        imageTypeId: string;
        file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
      }>;
    };
    storefrontImages: {
      __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
      nodes: Array<{
        __typename?: "CustomerPremiumBusinessListingSpecImage";
        id: number;
        customerId: number;
        imageTypeId: string;
        file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
      }>;
    };
    productImages: {
      __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
      nodes: Array<{
        __typename?: "CustomerPremiumBusinessListingSpecImage";
        id: number;
        customerId: number;
        imageTypeId: string;
        file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
      }>;
    };
  } | null;
};

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  updateCustomer: { __typename?: "UpdateCustomerResponse"; customer?: { __typename?: "Customer"; id: number } | null };
};

export type CustomerDetailsHoursOfOperationItemsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerDetailsHoursOfOperationItemsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerHoursOfOperationItems?: Array<{
      __typename?: "CustomerHoursOfOperationItem";
      id: number;
      dayIndex: number;
      isOpen: boolean;
      openAt: string;
      closeAt: string;
    }> | null;
  } | null;
};

export type SaveCustomerPremiumBusinessListingSpecMutationVariables = Exact<{
  input: SaveCustomerPremiumBusinessListingSpecInput;
}>;

export type SaveCustomerPremiumBusinessListingSpecMutation = {
  __typename?: "Mutation";
  saveCustomerPremiumBusinessListingSpec: {
    __typename?: "SaveCustomerPremiumBusinessListingSpecResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CustomerDetailsPremiumBusinessListingQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerDetailsPremiumBusinessListingQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerPremiumBusinessListingSpec?: {
      __typename?: "CustomerPremiumBusinessListingSpec";
      id: number;
      yearsInBusiness?: number | null;
      specialHoursOfOperation?: string | null;
      isAlwaysOpen?: boolean | null;
      priceRangeLevel?: number | null;
      shouldReceiveLeadMessages?: boolean | null;
      isWomenOwned?: boolean | null;
      isBlackOwned?: boolean | null;
    } | null;
  } | null;
};

export type SaveCustomerQuestionnaireMutationVariables = Exact<{
  input: SaveCustomerQuestionnaireInput;
}>;

export type SaveCustomerQuestionnaireMutation = {
  __typename?: "Mutation";
  saveCustomerQuestionnaire: {
    __typename?: "SaveCustomerQuestionnaireResponse";
    customerQuestionnaire: { __typename?: "CustomerQuestionnaire"; id: number };
  };
};

export type CustomerAccountQuestionnaireQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerAccountQuestionnaireQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    name: string;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      websiteHosting?: string | null;
      websiteCms?: string | null;
      whoCanChangeWebsite?: string | null;
      customerMarketSize?: string | null;
      businessDescription?: string | null;
      productsOffered?: string | null;
      targetKeywords?: string | null;
      preferredVoice?: string | null;
      targetAudience?: string | null;
      competitorWebsites?: string | null;
      contentPreference?: string | null;
      targetInternalLinks?: string | null;
      purposeOfContent?: string | null;
      callToActionUrls?: string | null;
      serviceArea?: string | null;
      contentPublishingPermissions?: string | null;
      isAddImage?: boolean | null;
    } | null;
  } | null;
};

export type SaveCustomerHoursOfOperationItemsMutationVariables = Exact<{
  input: SaveCustomerHoursOfOperationItemsInput;
}>;

export type SaveCustomerHoursOfOperationItemsMutation = {
  __typename?: "Mutation";
  saveCustomerHoursOfOperationItems: {
    __typename?: "SaveCustomerHoursOfOperationItemsResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadInput;
}>;

export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUpload: { __typename?: "GenerateUrlForFileUploadResponse"; url: string; fileId: string };
};

export type CustomerAnalyticsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerAnalyticsQuery = {
  __typename?: "Query";
  customer?: { __typename?: "Customer"; id: number; analyticsReportingInlineFrameUrl?: string | null } | null;
};

export type ConversationQueryVariables = Exact<{
  conversationId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type ConversationQuery = {
  __typename?: "Query";
  conversation?: {
    __typename?: "Conversation";
    id: string;
    subject: string;
    messages: {
      __typename?: "MessageConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Message";
        id: number;
        content: string;
        createdAt: Date;
        isFromCustomer: boolean;
        user: { __typename?: "User"; id: number; fullName: string };
      }>;
    };
  } | null;
};

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: "Mutation";
  createMessage: { __typename?: "CreateMessageResponse"; message: { __typename?: "Message"; id: number } };
};

export type Dashboard_ConversationFragment = { __typename?: "Conversation"; id: string; subject: string };

export type Dashboard_OrderFragment = {
  __typename?: "Order";
  id: number;
  workType: string;
  startDate: Date;
  status: { __typename?: "OrderStatus"; id: string; name: string };
};

export type Dashboard_MetricFragment = {
  __typename?: "Customer";
  id: number;
  keywordsOnFirstPage?: number | null;
  averageRanking?: number | null;
  keywordsImproved?: number | null;
};

export type DashboardConversationsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardConversationsQuery = {
  __typename?: "Query";
  conversations: {
    __typename?: "ConversationConnection";
    nodes: Array<{ __typename?: "Conversation"; id: string; subject: string }>;
  };
};

export type DashboardOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardOrdersQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    nodes: Array<{
      __typename?: "Order";
      id: number;
      workType: string;
      startDate: Date;
      status: { __typename?: "OrderStatus"; id: string; name: string };
    }>;
  };
};

export type DashboardMetricsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardMetricsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    keywordsOnFirstPage?: number | null;
    averageRanking?: number | null;
    keywordsImproved?: number | null;
  } | null;
};

export type DeliverableFileQueryVariables = Exact<{
  orderId: Scalars["Int"];
  fileId: Scalars["String"];
}>;

export type DeliverableFileQuery = {
  __typename?: "Query";
  deliverableFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
};

export type OrderGbpItemDeliveryQueryVariables = Exact<{
  publicId: Scalars["String"];
}>;

export type OrderGbpItemDeliveryQuery = {
  __typename?: "Query";
  orderGbpItem?: {
    __typename?: "OrderGbpItem";
    id: number;
    gbpItemDelivery: {
      __typename?: "GbpItemDelivery";
      content: string;
      contentOnlyText: string;
      publicUrl: string;
      thumbnailUrl?: string | null;
      readMoreUrl?: string | null;
      partnerName: string;
      partnerLogoUrl?: string | null;
    };
  } | null;
};

export type OrderContentItemDeliveryQueryVariables = Exact<{
  publicId: Scalars["String"];
}>;

export type OrderContentItemDeliveryQuery = {
  __typename?: "Query";
  orderContentItem?: {
    __typename?: "OrderContentItem";
    id: number;
    contentItemDelivery: {
      __typename?: "ContentItemDelivery";
      title: string;
      content: string;
      contentOnlyText: string;
      metaTitle: string;
      metaDescription: string;
      keyword: string;
      publicUrl: string;
      partnerName: string;
      partnerLogoUrl?: string | null;
      thumbnailUrl?: string | null;
      feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; feedback: string }>;
    };
  } | null;
};

export type Ui_CustomerKeywordRankingFragment = {
  __typename?: "CustomerKeywordRanking";
  id: number;
  createdAt: Date;
  rank: number;
};

export type Ui_CustomerKeywordFragment = {
  __typename?: "CustomerKeyword";
  id: number;
  name: string;
  location?: string | null;
  country?: string | null;
  isPrimary: boolean;
  startRank?: { __typename?: "CustomerKeywordRanking"; id: number; createdAt: Date; rank: number } | null;
  latestRank?: { __typename?: "CustomerKeywordRanking"; id: number; createdAt: Date; rank: number } | null;
};

export type CustomerKeywordsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersKeywordSort>;
  searchEngine: Scalars["String"];
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CustomerKeywordsQuery = {
  __typename?: "Query";
  customerKeywords: {
    __typename?: "CustomerKeywordConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "CustomerKeyword";
      id: number;
      name: string;
      location?: string | null;
      country?: string | null;
      isPrimary: boolean;
      startRank?: { __typename?: "CustomerKeywordRanking"; id: number; createdAt: Date; rank: number } | null;
      latestRank?: { __typename?: "CustomerKeywordRanking"; id: number; createdAt: Date; rank: number } | null;
    }>;
  };
};

export type KeywordRankingsQueryVariables = Exact<{
  customerKeywordId: Scalars["Int"];
  createdAt?: InputMaybe<DateQuery>;
  searchEngine: Scalars["String"];
}>;

export type KeywordRankingsQuery = {
  __typename?: "Query";
  customerKeywordRankings: {
    __typename?: "CustomerKeywordRankingConnection";
    nodes: Array<{ __typename?: "CustomerKeywordRanking"; id: number; createdAt: Date; rank: number }>;
  };
};

export type KeywordMetricsQueryVariables = Exact<{
  searchEngine: Scalars["String"];
}>;

export type KeywordMetricsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    keywordsOnFirstPage?: number | null;
    averageRanking?: number | null;
    keywordsImproved?: number | null;
    hasMapRanking: boolean;
  } | null;
};

export type LoginGoogleMutationVariables = Exact<{ [key: string]: never }>;

export type LoginGoogleMutation = {
  __typename?: "Mutation";
  loginGoogleService: { __typename?: "LoginGoogleResponse"; ok: boolean; url?: string | null };
};

export type LoginGoogleCallbackMutationVariables = Exact<{
  input?: InputMaybe<LoginGoogleCallbackInput>;
}>;

export type LoginGoogleCallbackMutation = {
  __typename?: "Mutation";
  loginGoogleCallback: {
    __typename?: "LoginGoogleCallbackResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type GoogleReviewReplyMutationVariables = Exact<{
  input: GoogleReviewReplyInput;
}>;

export type GoogleReviewReplyMutation = {
  __typename?: "Mutation";
  googleReviewReply: {
    __typename?: "GoogleReviewReplyResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type GetGoogleAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoogleAccountsQuery = {
  __typename?: "Query";
  getGoogleAccounts: {
    __typename?: "GoogleAccountsResponse";
    accounts?: Array<{ __typename?: "GoogleAccount"; name?: string | null; accountName?: string | null }> | null;
  };
};

export type GetGoogleLocationsQueryVariables = Exact<{
  account: Scalars["String"];
}>;

export type GetGoogleLocationsQuery = {
  __typename?: "Query";
  getGoogleLocations: {
    __typename?: "GoogleLocationsResponse";
    locations?: Array<{
      __typename?: "GoogleLocation";
      name?: string | null;
      title?: string | null;
      storefrontAddress?: {
        __typename?: "PostalAddress";
        addressLines?: Array<string> | null;
        locality?: string | null;
      } | null;
    }> | null;
  };
};

export type GetGoogleReviewsQueryVariables = Exact<{
  account: Scalars["String"];
  location: Scalars["String"];
  pageToken?: InputMaybe<Scalars["String"]>;
}>;

export type GetGoogleReviewsQuery = {
  __typename?: "Query";
  getGoogleReviews: {
    __typename?: "GoogleReviewRespose";
    nextPageToken?: string | null;
    totalReviewCount?: number | null;
    averageRating?: number | null;
    reviews?: Array<{
      __typename?: "GoogleReview";
      reviewId?: string | null;
      starRating?: string | null;
      comment?: string | null;
      createTime?: string | null;
      reviewer?: { __typename?: "Reviewer"; displayName?: string | null; profilePhotoUrl?: string | null } | null;
      reviewReply?: { __typename?: "ReviewReply"; comment?: string | null } | null;
    }> | null;
  };
};

export type GetGeminiReplyCommentQueryVariables = Exact<{
  comment: Scalars["String"];
  keywords: Scalars["String"];
}>;

export type GetGeminiReplyCommentQuery = {
  __typename?: "Query";
  geminiReplyComment: { __typename?: "ReplyComment"; reply: string };
};

export type LocationsReprtQueryVariables = Exact<{
  locationId: Scalars["Int"];
}>;

export type LocationsReprtQuery = {
  __typename?: "Query";
  customerBusinessListingSpec?: {
    __typename?: "CustomerBusinessListingSpec";
    id: number;
    address: string;
    city: string;
    stateOrProvince: string;
    country: string;
    zipPostalCode: string;
    phoneNumber: string;
    uberallListingDetails?: {
      __typename?: "UberallListingDetailsResponse";
      totalListings?: number | null;
      countListingsInSync?: number | null;
      countListingsBeingUpdated?: number | null;
      countListingsLinked?: number | null;
      countListingsSubmitted?: number | null;
      countListingsActionRequired?: number | null;
      listings?: Array<{
        __typename?: "UberallListingDetails";
        listingUrl?: string | null;
        typeName?: string | null;
        type?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserMutation = {
  __typename?: "Mutation";
  loginUser: { __typename?: "LoginUserResponse"; isLoggedIn?: boolean | null };
};

export type ConversationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type ConversationsQuery = {
  __typename?: "Query";
  conversations: {
    __typename?: "ConversationConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "Conversation"; id: string; subject: string; createdAt: Date }>;
  };
};

export type CreateConversationMutationVariables = Exact<{
  input: CreateConversationInput;
}>;

export type CreateConversationMutation = {
  __typename?: "Mutation";
  createConversation: {
    __typename?: "CreateConversationResponse";
    conversation: { __typename?: "Conversation"; id: string };
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "UpdateUserResponse"; user?: { __typename?: "User"; id: number } | null };
};

export type UserProfileQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type UserProfileQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    emailPreference?: string | null;
  } | null;
};

export type CustomersQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type CustomersQuery = {
  __typename?: "Query";
  customers: {
    __typename?: "CustomerConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Customer";
      id: number;
      name: string;
      partner: { __typename?: "Partner"; id: number; name: string };
    }>;
  };
};

export type Sso_LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type Sso_LoginUserMutation = {
  __typename?: "Mutation";
  loginUser: { __typename?: "LoginUserResponse"; isLoggedIn?: boolean | null; userId?: number | null };
};

export type TrackSsoEventMutationVariables = Exact<{
  input: CreateUserInteractionEventInput;
}>;

export type TrackSsoEventMutation = {
  __typename?: "Mutation";
  createUserInteractionEvent: {
    __typename?: "CreateUserInteractionEventResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type TaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type TaskWorkspaceQuery = {
  __typename?: "Query";
  task?: { __typename?: "Task"; id: number; taskType: { __typename?: "TaskType"; id: string; name: string } } | null;
};

export type SubmitContentCalendarReviewMutationVariables = Exact<{
  input: SubmitContentCalendarReviewInput;
}>;

export type SubmitContentCalendarReviewMutation = {
  __typename?: "Mutation";
  submitContentCalendarReview: {
    __typename?: "SubmitContentCalendarReviewResponse";
    task?: { __typename?: "Task"; id: number } | null;
  };
};

export type Ui_ProposedOrderTopicFragment = {
  __typename?: "ProposedOrderTopic";
  id: number;
  topic: string;
  rejectionComment?: string | null;
  order: { __typename?: "Order"; workType: string; startDate: Date };
  customerKeyword: { __typename?: "CustomerKeyword"; id: number; displayName: string };
};

export type ContentCalendarReviewTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentCalendarReviewTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    status: string;
    customerProposal?: {
      __typename?: "CustomerProposal";
      id: number;
      proposedOrderTopics: Array<{
        __typename?: "ProposedOrderTopic";
        id: number;
        topic: string;
        rejectionComment?: string | null;
        order: { __typename?: "Order"; workType: string; startDate: Date };
        customerKeyword: { __typename?: "CustomerKeyword"; id: number; displayName: string };
      }>;
    } | null;
  } | null;
};

export type SubmitContentReviewMutationVariables = Exact<{
  input: SubmitContentReviewInput;
}>;

export type SubmitContentReviewMutation = {
  __typename?: "Mutation";
  submitContentReview: {
    __typename?: "SubmitContentReviewResponse";
    task?: { __typename?: "Task"; id: number } | null;
  };
};

export type ContentReviewTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentReviewTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    status: string;
    contentItem?: {
      __typename?: "ContentItem";
      id: number;
      title: string;
      content: string;
      metaTitle: string;
      metaDescription: string;
      thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
    } | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: { __typename?: "Product"; id: string; name: string; description?: string | null };
    };
  } | null;
};

export type ContentReview_SaveReviewTaskContentItemMutationVariables = Exact<{
  input: SaveReviewTaskContentItemInput;
}>;

export type ContentReview_SaveReviewTaskContentItemMutation = {
  __typename?: "Mutation";
  saveReviewTaskContentItem: {
    __typename?: "SaveReviewTaskContentItemResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type ContentStrategyReview_OrderContentStrategyFragment = {
  __typename?: "OrderContentStrategy";
  id: number;
  createdAt: Date;
  topic?: string | null;
  order?: { __typename?: "Order"; id: number; startDate: Date; workType: string } | null;
  customerKeyword?: { __typename?: "CustomerKeyword"; id: number; displayName: string } | null;
};

export type ContentStrategyReviewQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentStrategyReviewQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    status: string;
    customerProposal?: {
      __typename?: "CustomerProposal";
      id: number;
      summary?: string | null;
      orderContentStrategies: Array<{
        __typename?: "OrderContentStrategy";
        id: number;
        createdAt: Date;
        topic?: string | null;
        order?: { __typename?: "Order"; id: number; startDate: Date; workType: string } | null;
        customerKeyword?: { __typename?: "CustomerKeyword"; id: number; displayName: string } | null;
      }>;
    } | null;
  } | null;
};

export type SaveContentStrategyReviewMutationVariables = Exact<{
  input: SaveContentStrategyReviewInput;
}>;

export type SaveContentStrategyReviewMutation = {
  __typename?: "Mutation";
  saveContentStrategyReview: {
    __typename?: "SaveContentStrategyReviewResponse";
    ok?: boolean | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type SubmitKeywordReviewMutationVariables = Exact<{
  input: SubmitKeywordReviewInput;
}>;

export type SubmitKeywordReviewMutation = {
  __typename?: "Mutation";
  submitKeywordReview: { __typename?: "SubmitKeywordReviewInputResponse"; done: boolean };
};

export type Ui_ProposedKeywordFragment = {
  __typename?: "ProposedCustomerKeyword";
  id: number;
  name: string;
  location?: string | null;
  country?: string | null;
  landingPageUrl?: string | null;
};

export type KeywordResearchReviewTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type KeywordResearchReviewTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    status: string;
    keywordReview?: {
      __typename?: "KeywordReview";
      id: number;
      summary?: string | null;
      primaryProposedKeywords: Array<{
        __typename?: "ProposedCustomerKeyword";
        id: number;
        name: string;
        location?: string | null;
        country?: string | null;
        landingPageUrl?: string | null;
      }>;
      haloProposedKeywords: Array<{
        __typename?: "ProposedCustomerKeyword";
        id: number;
        name: string;
        location?: string | null;
        country?: string | null;
        landingPageUrl?: string | null;
      }>;
    } | null;
  } | null;
};

export type WorkSummary_OrderFragment = {
  __typename?: "Order";
  id: number;
  topic?: string | null;
  instructions?: string | null;
  workType: string;
  completedAt?: Date | null;
  startDate: Date;
  resultUrl?: string | null;
  resultUrlDomainAuthority?: number | null;
  resultUrlTraffic?: number | null;
  customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
  status: { __typename?: "OrderStatus"; id: string; name: string };
  linkLocation?: {
    __typename?: "LinkLocation";
    id: number;
    spam?: number | null;
    authorityScore?: number | null;
    monthlyTraffic?: number | null;
  } | null;
};

export type WorkSummary_AmTaskFragment = {
  __typename?: "AmTask";
  id: number;
  taskType: string;
  completedAt: Date;
  info?: string | null;
};

export type WorkSummaryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
}>;

export type WorkSummaryQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    totalCount: number;
    pageInfo?: { __typename?: "PageInfo"; hasNextPage: boolean; hasPreviousPage: boolean } | null;
    nodes: Array<{
      __typename?: "Order";
      id: number;
      topic?: string | null;
      instructions?: string | null;
      workType: string;
      completedAt?: Date | null;
      startDate: Date;
      resultUrl?: string | null;
      resultUrlDomainAuthority?: number | null;
      resultUrlTraffic?: number | null;
      customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
      status: { __typename?: "OrderStatus"; id: string; name: string };
      linkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        spam?: number | null;
        authorityScore?: number | null;
        monthlyTraffic?: number | null;
      } | null;
    }>;
  };
  amTasks: {
    __typename?: "AmTaskConnection";
    nodes: Array<{ __typename?: "AmTask"; id: number; taskType: string; completedAt: Date; info?: string | null }>;
  };
};

export const AuthManager_ViewerUserFragmentDoc = gql`
  fragment AuthManager_ViewerUser on User {
    id
    fullName
  }
`;
export const AuthManager_ViewerFragmentDoc = gql`
  fragment AuthManager_Viewer on Viewer {
    user {
      ...AuthManager_ViewerUser
    }
    isAdmin
  }
  ${AuthManager_ViewerUserFragmentDoc}
`;
export const AuthManager_CustomerFragmentDoc = gql`
  fragment AuthManager_Customer on Customer {
    id
    name
    accountManagerUser {
      id
      calendarUrl
      title
      fullName
      profilePictureFile {
        id
        name
        url
      }
    }
  }
`;
export const Dashboard_ConversationFragmentDoc = gql`
  fragment Dashboard_Conversation on Conversation {
    id
    subject
  }
`;
export const Dashboard_OrderFragmentDoc = gql`
  fragment Dashboard_Order on Order {
    id
    workType
    status {
      id
      name
    }
    startDate
  }
`;
export const Dashboard_MetricFragmentDoc = gql`
  fragment Dashboard_Metric on Customer {
    id
    keywordsOnFirstPage
    averageRanking
    keywordsImproved
  }
`;
export const Ui_CustomerKeywordRankingFragmentDoc = gql`
  fragment UI_CustomerKeywordRanking on CustomerKeywordRanking {
    id
    createdAt
    rank
  }
`;
export const Ui_CustomerKeywordFragmentDoc = gql`
  fragment UI_CustomerKeyword on CustomerKeyword {
    id
    name
    location
    country
    isPrimary
    startRank(searchEngine: $searchEngine) {
      ...UI_CustomerKeywordRanking
    }
    latestRank(searchEngine: $searchEngine) {
      ...UI_CustomerKeywordRanking
    }
  }
  ${Ui_CustomerKeywordRankingFragmentDoc}
`;
export const Ui_ProposedOrderTopicFragmentDoc = gql`
  fragment UI_ProposedOrderTopic on ProposedOrderTopic {
    id
    order {
      workType
      startDate
    }
    topic
    rejectionComment
    customerKeyword {
      id
      displayName
    }
  }
`;
export const ContentStrategyReview_OrderContentStrategyFragmentDoc = gql`
  fragment ContentStrategyReview_OrderContentStrategy on OrderContentStrategy {
    id
    createdAt
    topic
    order {
      id
      startDate
      workType
    }
    customerKeyword {
      id
      displayName
    }
  }
`;
export const Ui_ProposedKeywordFragmentDoc = gql`
  fragment UI_ProposedKeyword on ProposedCustomerKeyword {
    id
    name
    location
    country
    landingPageUrl
  }
`;
export const WorkSummary_OrderFragmentDoc = gql`
  fragment WorkSummary_Order on Order {
    id
    topic
    instructions
    customerKeyword {
      id
      name
    }
    status {
      id
      name
    }
    workType
    completedAt
    startDate
    resultUrl
    resultUrlDomainAuthority
    resultUrlTraffic
    linkLocation {
      id
      spam
      authorityScore
      monthlyTraffic
    }
  }
`;
export const WorkSummary_AmTaskFragmentDoc = gql`
  fragment WorkSummary_AmTask on AmTask {
    id
    taskType
    completedAt
    info
  }
`;
export const ActionItemsBarDocument = gql`
  query ActionItemsBar {
    pendingTasks {
      id
      taskType {
        id
        name
      }
    }
  }
`;

/**
 * __useActionItemsBarQuery__
 *
 * To run a query within a React component, call `useActionItemsBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionItemsBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemsBarQuery({
 *   variables: {
 *   },
 * });
 */
export function useActionItemsBarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ActionItemsBarQuery, ActionItemsBarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ActionItemsBarQuery, ActionItemsBarQueryVariables>(ActionItemsBarDocument, options);
}
export function useActionItemsBarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActionItemsBarQuery, ActionItemsBarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ActionItemsBarQuery, ActionItemsBarQueryVariables>(
    ActionItemsBarDocument,
    options
  );
}
export type ActionItemsBarQueryHookResult = ReturnType<typeof useActionItemsBarQuery>;
export type ActionItemsBarLazyQueryHookResult = ReturnType<typeof useActionItemsBarLazyQuery>;
export type ActionItemsBarQueryResult = ApolloReactCommon.QueryResult<
  ActionItemsBarQuery,
  ActionItemsBarQueryVariables
>;
export const CategoriesForSelectorDocument = gql`
  query CategoriesForSelector($search: String, $limit: Int, $offset: Int, $categoryIds: [Int!]) {
    categories(search: $search, limit: $limit, offset: $offset, categoryIds: $categoryIds) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCategoriesForSelectorQuery__
 *
 * To run a query within a React component, call `useCategoriesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useCategoriesForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export function useCategoriesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export type CategoriesForSelectorQueryHookResult = ReturnType<typeof useCategoriesForSelectorQuery>;
export type CategoriesForSelectorLazyQueryHookResult = ReturnType<typeof useCategoriesForSelectorLazyQuery>;
export type CategoriesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  CategoriesForSelectorQuery,
  CategoriesForSelectorQueryVariables
>;
export const ClientLocationSelectorDocument = gql`
  query ClientLocationSelector($isListed: Boolean) {
    customer {
      id
      customerBusinessListingSpecs(isListed: $isListed) {
        nodes {
          id
          address
          city
        }
      }
    }
  }
`;

/**
 * __useClientLocationSelectorQuery__
 *
 * To run a query within a React component, call `useClientLocationSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientLocationSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientLocationSelectorQuery({
 *   variables: {
 *      isListed: // value for 'isListed'
 *   },
 * });
 */
export function useClientLocationSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClientLocationSelectorQuery, ClientLocationSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientLocationSelectorQuery, ClientLocationSelectorQueryVariables>(
    ClientLocationSelectorDocument,
    options
  );
}
export function useClientLocationSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientLocationSelectorQuery, ClientLocationSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientLocationSelectorQuery, ClientLocationSelectorQueryVariables>(
    ClientLocationSelectorDocument,
    options
  );
}
export type ClientLocationSelectorQueryHookResult = ReturnType<typeof useClientLocationSelectorQuery>;
export type ClientLocationSelectorLazyQueryHookResult = ReturnType<typeof useClientLocationSelectorLazyQuery>;
export type ClientLocationSelectorQueryResult = ApolloReactCommon.QueryResult<
  ClientLocationSelectorQuery,
  ClientLocationSelectorQueryVariables
>;
export const UploadFileDialog_GenerateUrlForFileUploadDocument = gql`
  mutation UploadFileDialog_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadInput!) {
    generateUrlForFileUpload(input: $input) {
      url
      fileId
      url
    }
  }
`;
export type UploadFileDialog_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  UploadFileDialog_GenerateUrlForFileUploadMutation,
  UploadFileDialog_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __useUploadFileDialog_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `useUploadFileDialog_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileDialog_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileDialogGenerateUrlForFileUploadMutation, { data, loading, error }] = useUploadFileDialog_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileDialog_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadFileDialog_GenerateUrlForFileUploadMutation,
    UploadFileDialog_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UploadFileDialog_GenerateUrlForFileUploadMutation,
    UploadFileDialog_GenerateUrlForFileUploadMutationVariables
  >(UploadFileDialog_GenerateUrlForFileUploadDocument, options);
}
export type UploadFileDialog_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof useUploadFileDialog_GenerateUrlForFileUploadMutation
>;
export type UploadFileDialog_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<UploadFileDialog_GenerateUrlForFileUploadMutation>;
export type UploadFileDialog_GenerateUrlForFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadFileDialog_GenerateUrlForFileUploadMutation,
  UploadFileDialog_GenerateUrlForFileUploadMutationVariables
>;
export const MainMenu_CurrentCustomerDocument = gql`
  query MainMenu_CurrentCustomer {
    customer {
      id
      name
    }
  }
`;

/**
 * __useMainMenu_CurrentCustomerQuery__
 *
 * To run a query within a React component, call `useMainMenu_CurrentCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainMenu_CurrentCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainMenu_CurrentCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainMenu_CurrentCustomerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MainMenu_CurrentCustomerQuery, MainMenu_CurrentCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MainMenu_CurrentCustomerQuery, MainMenu_CurrentCustomerQueryVariables>(
    MainMenu_CurrentCustomerDocument,
    options
  );
}
export function useMainMenu_CurrentCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MainMenu_CurrentCustomerQuery,
    MainMenu_CurrentCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MainMenu_CurrentCustomerQuery, MainMenu_CurrentCustomerQueryVariables>(
    MainMenu_CurrentCustomerDocument,
    options
  );
}
export type MainMenu_CurrentCustomerQueryHookResult = ReturnType<typeof useMainMenu_CurrentCustomerQuery>;
export type MainMenu_CurrentCustomerLazyQueryHookResult = ReturnType<typeof useMainMenu_CurrentCustomerLazyQuery>;
export type MainMenu_CurrentCustomerQueryResult = ApolloReactCommon.QueryResult<
  MainMenu_CurrentCustomerQuery,
  MainMenu_CurrentCustomerQueryVariables
>;
export const MainMenuPartnerLogoDocument = gql`
  query MainMenuPartnerLogo {
    customer {
      id
      customerBusinessListingSpecs {
        nodes {
          isReviewEnabled
        }
      }
      partner {
        id
        name
        reportingLogoUrl
      }
      analyticsReportingInlineFrameUrl
    }
  }
`;

/**
 * __useMainMenuPartnerLogoQuery__
 *
 * To run a query within a React component, call `useMainMenuPartnerLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainMenuPartnerLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainMenuPartnerLogoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainMenuPartnerLogoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MainMenuPartnerLogoQuery, MainMenuPartnerLogoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MainMenuPartnerLogoQuery, MainMenuPartnerLogoQueryVariables>(
    MainMenuPartnerLogoDocument,
    options
  );
}
export function useMainMenuPartnerLogoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MainMenuPartnerLogoQuery, MainMenuPartnerLogoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MainMenuPartnerLogoQuery, MainMenuPartnerLogoQueryVariables>(
    MainMenuPartnerLogoDocument,
    options
  );
}
export type MainMenuPartnerLogoQueryHookResult = ReturnType<typeof useMainMenuPartnerLogoQuery>;
export type MainMenuPartnerLogoLazyQueryHookResult = ReturnType<typeof useMainMenuPartnerLogoLazyQuery>;
export type MainMenuPartnerLogoQueryResult = ApolloReactCommon.QueryResult<
  MainMenuPartnerLogoQuery,
  MainMenuPartnerLogoQueryVariables
>;
export const AuthManagerDocument = gql`
  query AuthManager {
    viewer {
      ...AuthManager_Viewer
    }
    customer {
      ...AuthManager_Customer
    }
  }
  ${AuthManager_ViewerFragmentDoc}
  ${AuthManager_CustomerFragmentDoc}
`;

/**
 * __useAuthManagerQuery__
 *
 * To run a query within a React component, call `useAuthManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthManagerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export function useAuthManagerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export type AuthManagerQueryHookResult = ReturnType<typeof useAuthManagerQuery>;
export type AuthManagerLazyQueryHookResult = ReturnType<typeof useAuthManagerLazyQuery>;
export type AuthManagerQueryResult = ApolloReactCommon.QueryResult<AuthManagerQuery, AuthManagerQueryVariables>;
export const LogoutUserDocument = gql`
  mutation LogoutUser {
    logoutUser {
      isLoggedOut
    }
  }
`;
export type LogoutUserMutationFn = ApolloReactCommon.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
}
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = ApolloReactCommon.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;
export const CustomerAccountDocument = gql`
  query CustomerAccount {
    customer {
      id
      name
      website
      categoryId
    }
  }
`;

/**
 * __useCustomerAccountQuery__
 *
 * To run a query within a React component, call `useCustomerAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerAccountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerAccountQuery, CustomerAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerAccountQuery, CustomerAccountQueryVariables>(
    CustomerAccountDocument,
    options
  );
}
export function useCustomerAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerAccountQuery, CustomerAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerAccountQuery, CustomerAccountQueryVariables>(
    CustomerAccountDocument,
    options
  );
}
export type CustomerAccountQueryHookResult = ReturnType<typeof useCustomerAccountQuery>;
export type CustomerAccountLazyQueryHookResult = ReturnType<typeof useCustomerAccountLazyQuery>;
export type CustomerAccountQueryResult = ApolloReactCommon.QueryResult<
  CustomerAccountQuery,
  CustomerAccountQueryVariables
>;
export const CreateCustomerPremiumBusinessListingSpecImageDocument = gql`
  mutation CreateCustomerPremiumBusinessListingSpecImage($input: CreateCustomerPremiumBusinessListingSpecImageInput!) {
    createCustomerPremiumBusinessListingSpecImage(input: $input) {
      ok
      error {
        message
      }
      customerPremiumBusinessListingSpecImage {
        id
        customerId
        imageTypeId
        file {
          id
          name
          contentType
          dataUrl: url
          size: sizeInBytes
        }
      }
    }
  }
`;
export type CreateCustomerPremiumBusinessListingSpecImageMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerPremiumBusinessListingSpecImageMutation,
  CreateCustomerPremiumBusinessListingSpecImageMutationVariables
>;

/**
 * __useCreateCustomerPremiumBusinessListingSpecImageMutation__
 *
 * To run a mutation, you first call `useCreateCustomerPremiumBusinessListingSpecImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerPremiumBusinessListingSpecImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerPremiumBusinessListingSpecImageMutation, { data, loading, error }] = useCreateCustomerPremiumBusinessListingSpecImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerPremiumBusinessListingSpecImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerPremiumBusinessListingSpecImageMutation,
    CreateCustomerPremiumBusinessListingSpecImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerPremiumBusinessListingSpecImageMutation,
    CreateCustomerPremiumBusinessListingSpecImageMutationVariables
  >(CreateCustomerPremiumBusinessListingSpecImageDocument, options);
}
export type CreateCustomerPremiumBusinessListingSpecImageMutationHookResult = ReturnType<
  typeof useCreateCustomerPremiumBusinessListingSpecImageMutation
>;
export type CreateCustomerPremiumBusinessListingSpecImageMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerPremiumBusinessListingSpecImageMutation>;
export type CreateCustomerPremiumBusinessListingSpecImageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerPremiumBusinessListingSpecImageMutation,
  CreateCustomerPremiumBusinessListingSpecImageMutationVariables
>;
export const DeleteCustomerPremiumBusinessListingSpecImageDocument = gql`
  mutation DeleteCustomerPremiumBusinessListingSpecImage($input: DeleteCustomerPremiumBusinessListingSpecImageInput!) {
    deleteCustomerPremiumBusinessListingSpecImage(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type DeleteCustomerPremiumBusinessListingSpecImageMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCustomerPremiumBusinessListingSpecImageMutation,
  DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
>;

/**
 * __useDeleteCustomerPremiumBusinessListingSpecImageMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerPremiumBusinessListingSpecImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerPremiumBusinessListingSpecImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerPremiumBusinessListingSpecImageMutation, { data, loading, error }] = useDeleteCustomerPremiumBusinessListingSpecImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerPremiumBusinessListingSpecImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerPremiumBusinessListingSpecImageMutation,
    DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteCustomerPremiumBusinessListingSpecImageMutation,
    DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
  >(DeleteCustomerPremiumBusinessListingSpecImageDocument, options);
}
export type DeleteCustomerPremiumBusinessListingSpecImageMutationHookResult = ReturnType<
  typeof useDeleteCustomerPremiumBusinessListingSpecImageMutation
>;
export type DeleteCustomerPremiumBusinessListingSpecImageMutationResult =
  ApolloReactCommon.MutationResult<DeleteCustomerPremiumBusinessListingSpecImageMutation>;
export type DeleteCustomerPremiumBusinessListingSpecImageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCustomerPremiumBusinessListingSpecImageMutation,
  DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
>;
export const CustomerDetailsCompanyImagesDocument = gql`
  query CustomerDetailsCompanyImages {
    customer {
      id
      hasPremiumListing
      logoImage: customerPremiumBusinessListingSpecImages(imageTypeId: "logo") {
        nodes {
          id
          customerId
          imageTypeId
          file {
            id
            name
            contentType
            dataUrl: url
            size: sizeInBytes
          }
        }
      }
      storefrontImages: customerPremiumBusinessListingSpecImages(imageTypeId: "storefront") {
        nodes {
          id
          customerId
          imageTypeId
          file {
            id
            name
            contentType
            dataUrl: url
            size: sizeInBytes
          }
        }
      }
      productImages: customerPremiumBusinessListingSpecImages(imageTypeId: "product-or-service") {
        nodes {
          id
          customerId
          imageTypeId
          file {
            id
            name
            contentType
            dataUrl: url
            size: sizeInBytes
          }
        }
      }
    }
  }
`;

/**
 * __useCustomerDetailsCompanyImagesQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsCompanyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsCompanyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsCompanyImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerDetailsCompanyImagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsCompanyImagesQuery,
    CustomerDetailsCompanyImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerDetailsCompanyImagesQuery, CustomerDetailsCompanyImagesQueryVariables>(
    CustomerDetailsCompanyImagesDocument,
    options
  );
}
export function useCustomerDetailsCompanyImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsCompanyImagesQuery,
    CustomerDetailsCompanyImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerDetailsCompanyImagesQuery, CustomerDetailsCompanyImagesQueryVariables>(
    CustomerDetailsCompanyImagesDocument,
    options
  );
}
export type CustomerDetailsCompanyImagesQueryHookResult = ReturnType<typeof useCustomerDetailsCompanyImagesQuery>;
export type CustomerDetailsCompanyImagesLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsCompanyImagesLazyQuery
>;
export type CustomerDetailsCompanyImagesQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsCompanyImagesQuery,
  CustomerDetailsCompanyImagesQueryVariables
>;
export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    options
  );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const CustomerDetailsHoursOfOperationItemsDocument = gql`
  query CustomerDetailsHoursOfOperationItems {
    customer {
      id
      customerHoursOfOperationItems {
        id
        dayIndex
        isOpen
        openAt
        closeAt
      }
    }
  }
`;

/**
 * __useCustomerDetailsHoursOfOperationItemsQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsHoursOfOperationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsHoursOfOperationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsHoursOfOperationItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerDetailsHoursOfOperationItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >(CustomerDetailsHoursOfOperationItemsDocument, options);
}
export function useCustomerDetailsHoursOfOperationItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >(CustomerDetailsHoursOfOperationItemsDocument, options);
}
export type CustomerDetailsHoursOfOperationItemsQueryHookResult = ReturnType<
  typeof useCustomerDetailsHoursOfOperationItemsQuery
>;
export type CustomerDetailsHoursOfOperationItemsLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsHoursOfOperationItemsLazyQuery
>;
export type CustomerDetailsHoursOfOperationItemsQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsHoursOfOperationItemsQuery,
  CustomerDetailsHoursOfOperationItemsQueryVariables
>;
export const SaveCustomerPremiumBusinessListingSpecDocument = gql`
  mutation SaveCustomerPremiumBusinessListingSpec($input: SaveCustomerPremiumBusinessListingSpecInput!) {
    saveCustomerPremiumBusinessListingSpec(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveCustomerPremiumBusinessListingSpecMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerPremiumBusinessListingSpecMutation,
  SaveCustomerPremiumBusinessListingSpecMutationVariables
>;

/**
 * __useSaveCustomerPremiumBusinessListingSpecMutation__
 *
 * To run a mutation, you first call `useSaveCustomerPremiumBusinessListingSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerPremiumBusinessListingSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerPremiumBusinessListingSpecMutation, { data, loading, error }] = useSaveCustomerPremiumBusinessListingSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerPremiumBusinessListingSpecMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerPremiumBusinessListingSpecMutation,
    SaveCustomerPremiumBusinessListingSpecMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveCustomerPremiumBusinessListingSpecMutation,
    SaveCustomerPremiumBusinessListingSpecMutationVariables
  >(SaveCustomerPremiumBusinessListingSpecDocument, options);
}
export type SaveCustomerPremiumBusinessListingSpecMutationHookResult = ReturnType<
  typeof useSaveCustomerPremiumBusinessListingSpecMutation
>;
export type SaveCustomerPremiumBusinessListingSpecMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerPremiumBusinessListingSpecMutation>;
export type SaveCustomerPremiumBusinessListingSpecMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerPremiumBusinessListingSpecMutation,
  SaveCustomerPremiumBusinessListingSpecMutationVariables
>;
export const CustomerDetailsPremiumBusinessListingDocument = gql`
  query CustomerDetailsPremiumBusinessListing {
    customer {
      id
      customerPremiumBusinessListingSpec {
        id
        yearsInBusiness
        specialHoursOfOperation
        isAlwaysOpen
        priceRangeLevel
        shouldReceiveLeadMessages
        isWomenOwned
        isBlackOwned
      }
    }
  }
`;

/**
 * __useCustomerDetailsPremiumBusinessListingQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsPremiumBusinessListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsPremiumBusinessListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsPremiumBusinessListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerDetailsPremiumBusinessListingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >(CustomerDetailsPremiumBusinessListingDocument, options);
}
export function useCustomerDetailsPremiumBusinessListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >(CustomerDetailsPremiumBusinessListingDocument, options);
}
export type CustomerDetailsPremiumBusinessListingQueryHookResult = ReturnType<
  typeof useCustomerDetailsPremiumBusinessListingQuery
>;
export type CustomerDetailsPremiumBusinessListingLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsPremiumBusinessListingLazyQuery
>;
export type CustomerDetailsPremiumBusinessListingQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsPremiumBusinessListingQuery,
  CustomerDetailsPremiumBusinessListingQueryVariables
>;
export const SaveCustomerQuestionnaireDocument = gql`
  mutation SaveCustomerQuestionnaire($input: SaveCustomerQuestionnaireInput!) {
    saveCustomerQuestionnaire(input: $input) {
      customerQuestionnaire {
        id
      }
    }
  }
`;
export type SaveCustomerQuestionnaireMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerQuestionnaireMutation,
  SaveCustomerQuestionnaireMutationVariables
>;

/**
 * __useSaveCustomerQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSaveCustomerQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerQuestionnaireMutation, { data, loading, error }] = useSaveCustomerQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerQuestionnaireMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerQuestionnaireMutation,
    SaveCustomerQuestionnaireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveCustomerQuestionnaireMutation, SaveCustomerQuestionnaireMutationVariables>(
    SaveCustomerQuestionnaireDocument,
    options
  );
}
export type SaveCustomerQuestionnaireMutationHookResult = ReturnType<typeof useSaveCustomerQuestionnaireMutation>;
export type SaveCustomerQuestionnaireMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerQuestionnaireMutation>;
export type SaveCustomerQuestionnaireMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerQuestionnaireMutation,
  SaveCustomerQuestionnaireMutationVariables
>;
export const CustomerAccountQuestionnaireDocument = gql`
  query CustomerAccountQuestionnaire {
    customer {
      id
      name
      customerQuestionnaire {
        id
        websiteHosting
        websiteCms
        whoCanChangeWebsite
        customerMarketSize
        businessDescription
        productsOffered
        targetKeywords
        preferredVoice
        targetAudience
        competitorWebsites
        contentPreference
        targetInternalLinks
        purposeOfContent
        callToActionUrls
        serviceArea
        contentPublishingPermissions
        isAddImage
      }
    }
  }
`;

/**
 * __useCustomerAccountQuestionnaireQuery__
 *
 * To run a query within a React component, call `useCustomerAccountQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAccountQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAccountQuestionnaireQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerAccountQuestionnaireQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerAccountQuestionnaireQuery,
    CustomerAccountQuestionnaireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerAccountQuestionnaireQuery, CustomerAccountQuestionnaireQueryVariables>(
    CustomerAccountQuestionnaireDocument,
    options
  );
}
export function useCustomerAccountQuestionnaireLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerAccountQuestionnaireQuery,
    CustomerAccountQuestionnaireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerAccountQuestionnaireQuery, CustomerAccountQuestionnaireQueryVariables>(
    CustomerAccountQuestionnaireDocument,
    options
  );
}
export type CustomerAccountQuestionnaireQueryHookResult = ReturnType<typeof useCustomerAccountQuestionnaireQuery>;
export type CustomerAccountQuestionnaireLazyQueryHookResult = ReturnType<
  typeof useCustomerAccountQuestionnaireLazyQuery
>;
export type CustomerAccountQuestionnaireQueryResult = ApolloReactCommon.QueryResult<
  CustomerAccountQuestionnaireQuery,
  CustomerAccountQuestionnaireQueryVariables
>;
export const SaveCustomerHoursOfOperationItemsDocument = gql`
  mutation SaveCustomerHoursOfOperationItems($input: SaveCustomerHoursOfOperationItemsInput!) {
    saveCustomerHoursOfOperationItems(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveCustomerHoursOfOperationItemsMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerHoursOfOperationItemsMutation,
  SaveCustomerHoursOfOperationItemsMutationVariables
>;

/**
 * __useSaveCustomerHoursOfOperationItemsMutation__
 *
 * To run a mutation, you first call `useSaveCustomerHoursOfOperationItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerHoursOfOperationItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerHoursOfOperationItemsMutation, { data, loading, error }] = useSaveCustomerHoursOfOperationItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerHoursOfOperationItemsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerHoursOfOperationItemsMutation,
    SaveCustomerHoursOfOperationItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveCustomerHoursOfOperationItemsMutation,
    SaveCustomerHoursOfOperationItemsMutationVariables
  >(SaveCustomerHoursOfOperationItemsDocument, options);
}
export type SaveCustomerHoursOfOperationItemsMutationHookResult = ReturnType<
  typeof useSaveCustomerHoursOfOperationItemsMutation
>;
export type SaveCustomerHoursOfOperationItemsMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerHoursOfOperationItemsMutation>;
export type SaveCustomerHoursOfOperationItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerHoursOfOperationItemsMutation,
  SaveCustomerHoursOfOperationItemsMutationVariables
>;
export const PremiumBusinessListingSpecImage_GenerateUrlForFileUploadDocument = gql`
  mutation PremiumBusinessListingSpecImage_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadInput!) {
    generateUrlForFileUpload(input: $input) {
      url
      fileId
    }
  }
`;
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
  PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [premiumBusinessListingSpecImageGenerateUrlForFileUploadMutation, { data, loading, error }] = usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
  >(PremiumBusinessListingSpecImage_GenerateUrlForFileUploadDocument, options);
}
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation
>;
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation>;
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
  >;
export const CustomerAnalyticsDocument = gql`
  query CustomerAnalytics {
    customer {
      id
      analyticsReportingInlineFrameUrl
    }
  }
`;

/**
 * __useCustomerAnalyticsQuery__
 *
 * To run a query within a React component, call `useCustomerAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerAnalyticsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerAnalyticsQuery, CustomerAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerAnalyticsQuery, CustomerAnalyticsQueryVariables>(
    CustomerAnalyticsDocument,
    options
  );
}
export function useCustomerAnalyticsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerAnalyticsQuery, CustomerAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerAnalyticsQuery, CustomerAnalyticsQueryVariables>(
    CustomerAnalyticsDocument,
    options
  );
}
export type CustomerAnalyticsQueryHookResult = ReturnType<typeof useCustomerAnalyticsQuery>;
export type CustomerAnalyticsLazyQueryHookResult = ReturnType<typeof useCustomerAnalyticsLazyQuery>;
export type CustomerAnalyticsQueryResult = ApolloReactCommon.QueryResult<
  CustomerAnalyticsQuery,
  CustomerAnalyticsQueryVariables
>;
export const ConversationDocument = gql`
  query Conversation($conversationId: String!, $limit: Int, $offset: Int) {
    conversation(conversationId: $conversationId) {
      id
      subject
      messages(limit: $limit, offset: $offset, sort: CREATED_AT_ASC) {
        totalCount
        nodes {
          id
          content
          createdAt
          isFromCustomer
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useConversationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ConversationQuery, ConversationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
}
export function useConversationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
}
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = ApolloReactCommon.QueryResult<ConversationQuery, ConversationQueryVariables>;
export const CreateMessageDocument = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        id
      }
    }
  }
`;
export type CreateMessageMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(
    CreateMessageDocument,
    options
  );
}
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = ApolloReactCommon.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const DashboardConversationsDocument = gql`
  query DashboardConversations {
    conversations(limit: 4) {
      nodes {
        ...Dashboard_Conversation
      }
    }
  }
  ${Dashboard_ConversationFragmentDoc}
`;

/**
 * __useDashboardConversationsQuery__
 *
 * To run a query within a React component, call `useDashboardConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardConversationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardConversationsQuery, DashboardConversationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DashboardConversationsQuery, DashboardConversationsQueryVariables>(
    DashboardConversationsDocument,
    options
  );
}
export function useDashboardConversationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardConversationsQuery, DashboardConversationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DashboardConversationsQuery, DashboardConversationsQueryVariables>(
    DashboardConversationsDocument,
    options
  );
}
export type DashboardConversationsQueryHookResult = ReturnType<typeof useDashboardConversationsQuery>;
export type DashboardConversationsLazyQueryHookResult = ReturnType<typeof useDashboardConversationsLazyQuery>;
export type DashboardConversationsQueryResult = ApolloReactCommon.QueryResult<
  DashboardConversationsQuery,
  DashboardConversationsQueryVariables
>;
export const DashboardOrdersDocument = gql`
  query DashboardOrders {
    orders(limit: 5, sort: STARTED_AT_DESC, statusIds: ["in-progress", "completed"]) {
      nodes {
        ...Dashboard_Order
      }
    }
  }
  ${Dashboard_OrderFragmentDoc}
`;

/**
 * __useDashboardOrdersQuery__
 *
 * To run a query within a React component, call `useDashboardOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardOrdersQuery, DashboardOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DashboardOrdersQuery, DashboardOrdersQueryVariables>(
    DashboardOrdersDocument,
    options
  );
}
export function useDashboardOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardOrdersQuery, DashboardOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DashboardOrdersQuery, DashboardOrdersQueryVariables>(
    DashboardOrdersDocument,
    options
  );
}
export type DashboardOrdersQueryHookResult = ReturnType<typeof useDashboardOrdersQuery>;
export type DashboardOrdersLazyQueryHookResult = ReturnType<typeof useDashboardOrdersLazyQuery>;
export type DashboardOrdersQueryResult = ApolloReactCommon.QueryResult<
  DashboardOrdersQuery,
  DashboardOrdersQueryVariables
>;
export const DashboardMetricsDocument = gql`
  query DashboardMetrics {
    customer {
      ...Dashboard_Metric
    }
  }
  ${Dashboard_MetricFragmentDoc}
`;

/**
 * __useDashboardMetricsQuery__
 *
 * To run a query within a React component, call `useDashboardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardMetricsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardMetricsQuery, DashboardMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DashboardMetricsQuery, DashboardMetricsQueryVariables>(
    DashboardMetricsDocument,
    options
  );
}
export function useDashboardMetricsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardMetricsQuery, DashboardMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DashboardMetricsQuery, DashboardMetricsQueryVariables>(
    DashboardMetricsDocument,
    options
  );
}
export type DashboardMetricsQueryHookResult = ReturnType<typeof useDashboardMetricsQuery>;
export type DashboardMetricsLazyQueryHookResult = ReturnType<typeof useDashboardMetricsLazyQuery>;
export type DashboardMetricsQueryResult = ApolloReactCommon.QueryResult<
  DashboardMetricsQuery,
  DashboardMetricsQueryVariables
>;
export const DeliverableFileDocument = gql`
  query DeliverableFile($orderId: Int!, $fileId: String!) {
    deliverableFile(orderId: $orderId, fileId: $fileId) {
      id
      name
      url
    }
  }
`;

/**
 * __useDeliverableFileQuery__
 *
 * To run a query within a React component, call `useDeliverableFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverableFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverableFileQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeliverableFileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DeliverableFileQuery, DeliverableFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DeliverableFileQuery, DeliverableFileQueryVariables>(
    DeliverableFileDocument,
    options
  );
}
export function useDeliverableFileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliverableFileQuery, DeliverableFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DeliverableFileQuery, DeliverableFileQueryVariables>(
    DeliverableFileDocument,
    options
  );
}
export type DeliverableFileQueryHookResult = ReturnType<typeof useDeliverableFileQuery>;
export type DeliverableFileLazyQueryHookResult = ReturnType<typeof useDeliverableFileLazyQuery>;
export type DeliverableFileQueryResult = ApolloReactCommon.QueryResult<
  DeliverableFileQuery,
  DeliverableFileQueryVariables
>;
export const OrderGbpItemDeliveryDocument = gql`
  query OrderGbpItemDelivery($publicId: String!) {
    orderGbpItem(publicId: $publicId) {
      id
      gbpItemDelivery {
        content
        contentOnlyText
        publicUrl
        thumbnailUrl
        readMoreUrl
        partnerName
        partnerLogoUrl
      }
    }
  }
`;

/**
 * __useOrderGbpItemDeliveryQuery__
 *
 * To run a query within a React component, call `useOrderGbpItemDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderGbpItemDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderGbpItemDeliveryQuery({
 *   variables: {
 *      publicId: // value for 'publicId'
 *   },
 * });
 */
export function useOrderGbpItemDeliveryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrderGbpItemDeliveryQuery, OrderGbpItemDeliveryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderGbpItemDeliveryQuery, OrderGbpItemDeliveryQueryVariables>(
    OrderGbpItemDeliveryDocument,
    options
  );
}
export function useOrderGbpItemDeliveryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderGbpItemDeliveryQuery, OrderGbpItemDeliveryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderGbpItemDeliveryQuery, OrderGbpItemDeliveryQueryVariables>(
    OrderGbpItemDeliveryDocument,
    options
  );
}
export type OrderGbpItemDeliveryQueryHookResult = ReturnType<typeof useOrderGbpItemDeliveryQuery>;
export type OrderGbpItemDeliveryLazyQueryHookResult = ReturnType<typeof useOrderGbpItemDeliveryLazyQuery>;
export type OrderGbpItemDeliveryQueryResult = ApolloReactCommon.QueryResult<
  OrderGbpItemDeliveryQuery,
  OrderGbpItemDeliveryQueryVariables
>;
export const OrderContentItemDeliveryDocument = gql`
  query OrderContentItemDelivery($publicId: String!) {
    orderContentItem(publicId: $publicId) {
      id
      contentItemDelivery {
        title
        content
        contentOnlyText
        metaTitle
        metaDescription
        keyword
        publicUrl
        partnerName
        partnerLogoUrl
        thumbnailUrl
        feedbackContentItems {
          id
          feedback
        }
      }
    }
  }
`;

/**
 * __useOrderContentItemDeliveryQuery__
 *
 * To run a query within a React component, call `useOrderContentItemDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderContentItemDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderContentItemDeliveryQuery({
 *   variables: {
 *      publicId: // value for 'publicId'
 *   },
 * });
 */
export function useOrderContentItemDeliveryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrderContentItemDeliveryQuery, OrderContentItemDeliveryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderContentItemDeliveryQuery, OrderContentItemDeliveryQueryVariables>(
    OrderContentItemDeliveryDocument,
    options
  );
}
export function useOrderContentItemDeliveryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderContentItemDeliveryQuery,
    OrderContentItemDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderContentItemDeliveryQuery, OrderContentItemDeliveryQueryVariables>(
    OrderContentItemDeliveryDocument,
    options
  );
}
export type OrderContentItemDeliveryQueryHookResult = ReturnType<typeof useOrderContentItemDeliveryQuery>;
export type OrderContentItemDeliveryLazyQueryHookResult = ReturnType<typeof useOrderContentItemDeliveryLazyQuery>;
export type OrderContentItemDeliveryQueryResult = ApolloReactCommon.QueryResult<
  OrderContentItemDeliveryQuery,
  OrderContentItemDeliveryQueryVariables
>;
export const CustomerKeywordsDocument = gql`
  query CustomerKeywords(
    $limit: Int
    $offset: Int
    $search: String
    $sort: CustomersKeywordSort
    $searchEngine: String!
    $isTrackingMapRank: Boolean
  ) {
    customerKeywords(
      limit: $limit
      offset: $offset
      search: $search
      sort: $sort
      isTrackingMapRank: $isTrackingMapRank
    ) {
      totalCount
      nodes {
        ...UI_CustomerKeyword
      }
    }
  }
  ${Ui_CustomerKeywordFragmentDoc}
`;

/**
 * __useCustomerKeywordsQuery__
 *
 * To run a query within a React component, call `useCustomerKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerKeywordsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      searchEngine: // value for 'searchEngine'
 *      isTrackingMapRank: // value for 'isTrackingMapRank'
 *   },
 * });
 */
export function useCustomerKeywordsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>(
    CustomerKeywordsDocument,
    options
  );
}
export function useCustomerKeywordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>(
    CustomerKeywordsDocument,
    options
  );
}
export type CustomerKeywordsQueryHookResult = ReturnType<typeof useCustomerKeywordsQuery>;
export type CustomerKeywordsLazyQueryHookResult = ReturnType<typeof useCustomerKeywordsLazyQuery>;
export type CustomerKeywordsQueryResult = ApolloReactCommon.QueryResult<
  CustomerKeywordsQuery,
  CustomerKeywordsQueryVariables
>;
export const KeywordRankingsDocument = gql`
  query KeywordRankings($customerKeywordId: Int!, $createdAt: DateQuery, $searchEngine: String!) {
    customerKeywordRankings(
      customerKeywordId: $customerKeywordId
      createdAt: $createdAt
      sort: DATE_ASC
      searchEngine: $searchEngine
    ) {
      nodes {
        ...UI_CustomerKeywordRanking
      }
    }
  }
  ${Ui_CustomerKeywordRankingFragmentDoc}
`;

/**
 * __useKeywordRankingsQuery__
 *
 * To run a query within a React component, call `useKeywordRankingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordRankingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordRankingsQuery({
 *   variables: {
 *      customerKeywordId: // value for 'customerKeywordId'
 *      createdAt: // value for 'createdAt'
 *      searchEngine: // value for 'searchEngine'
 *   },
 * });
 */
export function useKeywordRankingsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<KeywordRankingsQuery, KeywordRankingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<KeywordRankingsQuery, KeywordRankingsQueryVariables>(
    KeywordRankingsDocument,
    options
  );
}
export function useKeywordRankingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeywordRankingsQuery, KeywordRankingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<KeywordRankingsQuery, KeywordRankingsQueryVariables>(
    KeywordRankingsDocument,
    options
  );
}
export type KeywordRankingsQueryHookResult = ReturnType<typeof useKeywordRankingsQuery>;
export type KeywordRankingsLazyQueryHookResult = ReturnType<typeof useKeywordRankingsLazyQuery>;
export type KeywordRankingsQueryResult = ApolloReactCommon.QueryResult<
  KeywordRankingsQuery,
  KeywordRankingsQueryVariables
>;
export const KeywordMetricsDocument = gql`
  query KeywordMetrics($searchEngine: String!) {
    customer {
      id
      keywordsOnFirstPage(searchEngine: $searchEngine)
      averageRanking(searchEngine: $searchEngine)
      keywordsImproved(searchEngine: $searchEngine)
      hasMapRanking
    }
  }
`;

/**
 * __useKeywordMetricsQuery__
 *
 * To run a query within a React component, call `useKeywordMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordMetricsQuery({
 *   variables: {
 *      searchEngine: // value for 'searchEngine'
 *   },
 * });
 */
export function useKeywordMetricsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<KeywordMetricsQuery, KeywordMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<KeywordMetricsQuery, KeywordMetricsQueryVariables>(KeywordMetricsDocument, options);
}
export function useKeywordMetricsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeywordMetricsQuery, KeywordMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<KeywordMetricsQuery, KeywordMetricsQueryVariables>(
    KeywordMetricsDocument,
    options
  );
}
export type KeywordMetricsQueryHookResult = ReturnType<typeof useKeywordMetricsQuery>;
export type KeywordMetricsLazyQueryHookResult = ReturnType<typeof useKeywordMetricsLazyQuery>;
export type KeywordMetricsQueryResult = ApolloReactCommon.QueryResult<
  KeywordMetricsQuery,
  KeywordMetricsQueryVariables
>;
export const LoginGoogleDocument = gql`
  mutation LoginGoogle {
    loginGoogleService {
      ok
      url
    }
  }
`;
export type LoginGoogleMutationFn = ApolloReactCommon.MutationFunction<
  LoginGoogleMutation,
  LoginGoogleMutationVariables
>;

/**
 * __useLoginGoogleMutation__
 *
 * To run a mutation, you first call `useLoginGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleMutation, { data, loading, error }] = useLoginGoogleMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoginGoogleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginGoogleMutation, LoginGoogleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginGoogleMutation, LoginGoogleMutationVariables>(LoginGoogleDocument, options);
}
export type LoginGoogleMutationHookResult = ReturnType<typeof useLoginGoogleMutation>;
export type LoginGoogleMutationResult = ApolloReactCommon.MutationResult<LoginGoogleMutation>;
export type LoginGoogleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginGoogleMutation,
  LoginGoogleMutationVariables
>;
export const LoginGoogleCallbackDocument = gql`
  mutation LoginGoogleCallback($input: LoginGoogleCallbackInput) {
    loginGoogleCallback(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type LoginGoogleCallbackMutationFn = ApolloReactCommon.MutationFunction<
  LoginGoogleCallbackMutation,
  LoginGoogleCallbackMutationVariables
>;

/**
 * __useLoginGoogleCallbackMutation__
 *
 * To run a mutation, you first call `useLoginGoogleCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleCallbackMutation, { data, loading, error }] = useLoginGoogleCallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginGoogleCallbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginGoogleCallbackMutation, LoginGoogleCallbackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginGoogleCallbackMutation, LoginGoogleCallbackMutationVariables>(
    LoginGoogleCallbackDocument,
    options
  );
}
export type LoginGoogleCallbackMutationHookResult = ReturnType<typeof useLoginGoogleCallbackMutation>;
export type LoginGoogleCallbackMutationResult = ApolloReactCommon.MutationResult<LoginGoogleCallbackMutation>;
export type LoginGoogleCallbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginGoogleCallbackMutation,
  LoginGoogleCallbackMutationVariables
>;
export const GoogleReviewReplyDocument = gql`
  mutation GoogleReviewReply($input: GoogleReviewReplyInput!) {
    googleReviewReply(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type GoogleReviewReplyMutationFn = ApolloReactCommon.MutationFunction<
  GoogleReviewReplyMutation,
  GoogleReviewReplyMutationVariables
>;

/**
 * __useGoogleReviewReplyMutation__
 *
 * To run a mutation, you first call `useGoogleReviewReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleReviewReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleReviewReplyMutation, { data, loading, error }] = useGoogleReviewReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleReviewReplyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<GoogleReviewReplyMutation, GoogleReviewReplyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GoogleReviewReplyMutation, GoogleReviewReplyMutationVariables>(
    GoogleReviewReplyDocument,
    options
  );
}
export type GoogleReviewReplyMutationHookResult = ReturnType<typeof useGoogleReviewReplyMutation>;
export type GoogleReviewReplyMutationResult = ApolloReactCommon.MutationResult<GoogleReviewReplyMutation>;
export type GoogleReviewReplyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GoogleReviewReplyMutation,
  GoogleReviewReplyMutationVariables
>;
export const GetGoogleAccountsDocument = gql`
  query GetGoogleAccounts {
    getGoogleAccounts {
      accounts {
        name
        accountName
      }
    }
  }
`;

/**
 * __useGetGoogleAccountsQuery__
 *
 * To run a query within a React component, call `useGetGoogleAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>(
    GetGoogleAccountsDocument,
    options
  );
}
export function useGetGoogleAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>(
    GetGoogleAccountsDocument,
    options
  );
}
export type GetGoogleAccountsQueryHookResult = ReturnType<typeof useGetGoogleAccountsQuery>;
export type GetGoogleAccountsLazyQueryHookResult = ReturnType<typeof useGetGoogleAccountsLazyQuery>;
export type GetGoogleAccountsQueryResult = ApolloReactCommon.QueryResult<
  GetGoogleAccountsQuery,
  GetGoogleAccountsQueryVariables
>;
export const GetGoogleLocationsDocument = gql`
  query GetGoogleLocations($account: String!) {
    getGoogleLocations(account: $account) {
      locations {
        name
        title
        storefrontAddress {
          addressLines
          locality
        }
      }
    }
  }
`;

/**
 * __useGetGoogleLocationsQuery__
 *
 * To run a query within a React component, call `useGetGoogleLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleLocationsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetGoogleLocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>(
    GetGoogleLocationsDocument,
    options
  );
}
export function useGetGoogleLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>(
    GetGoogleLocationsDocument,
    options
  );
}
export type GetGoogleLocationsQueryHookResult = ReturnType<typeof useGetGoogleLocationsQuery>;
export type GetGoogleLocationsLazyQueryHookResult = ReturnType<typeof useGetGoogleLocationsLazyQuery>;
export type GetGoogleLocationsQueryResult = ApolloReactCommon.QueryResult<
  GetGoogleLocationsQuery,
  GetGoogleLocationsQueryVariables
>;
export const GetGoogleReviewsDocument = gql`
  query GetGoogleReviews($account: String!, $location: String!, $pageToken: String) {
    getGoogleReviews(account: $account, location: $location, pageToken: $pageToken) {
      reviews {
        reviewId
        reviewer {
          displayName
          profilePhotoUrl
        }
        starRating
        comment
        createTime
        reviewReply {
          comment
        }
      }
      nextPageToken
      totalReviewCount
      averageRating
    }
  }
`;

/**
 * __useGetGoogleReviewsQuery__
 *
 * To run a query within a React component, call `useGetGoogleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleReviewsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      location: // value for 'location'
 *      pageToken: // value for 'pageToken'
 *   },
 * });
 */
export function useGetGoogleReviewsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>(
    GetGoogleReviewsDocument,
    options
  );
}
export function useGetGoogleReviewsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>(
    GetGoogleReviewsDocument,
    options
  );
}
export type GetGoogleReviewsQueryHookResult = ReturnType<typeof useGetGoogleReviewsQuery>;
export type GetGoogleReviewsLazyQueryHookResult = ReturnType<typeof useGetGoogleReviewsLazyQuery>;
export type GetGoogleReviewsQueryResult = ApolloReactCommon.QueryResult<
  GetGoogleReviewsQuery,
  GetGoogleReviewsQueryVariables
>;
export const GetGeminiReplyCommentDocument = gql`
  query GetGeminiReplyComment($comment: String!, $keywords: String!) {
    geminiReplyComment(comment: $comment, keywords: $keywords) {
      reply
    }
  }
`;

/**
 * __useGetGeminiReplyCommentQuery__
 *
 * To run a query within a React component, call `useGetGeminiReplyCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeminiReplyCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeminiReplyCommentQuery({
 *   variables: {
 *      comment: // value for 'comment'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useGetGeminiReplyCommentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetGeminiReplyCommentQuery, GetGeminiReplyCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGeminiReplyCommentQuery, GetGeminiReplyCommentQueryVariables>(
    GetGeminiReplyCommentDocument,
    options
  );
}
export function useGetGeminiReplyCommentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeminiReplyCommentQuery, GetGeminiReplyCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGeminiReplyCommentQuery, GetGeminiReplyCommentQueryVariables>(
    GetGeminiReplyCommentDocument,
    options
  );
}
export type GetGeminiReplyCommentQueryHookResult = ReturnType<typeof useGetGeminiReplyCommentQuery>;
export type GetGeminiReplyCommentLazyQueryHookResult = ReturnType<typeof useGetGeminiReplyCommentLazyQuery>;
export type GetGeminiReplyCommentQueryResult = ApolloReactCommon.QueryResult<
  GetGeminiReplyCommentQuery,
  GetGeminiReplyCommentQueryVariables
>;
export const LocationsReprtDocument = gql`
  query LocationsReprt($locationId: Int!) {
    customerBusinessListingSpec(id: $locationId) {
      id
      address
      city
      stateOrProvince
      country
      zipPostalCode
      phoneNumber
      uberallListingDetails {
        listings {
          listingUrl
          typeName
          type
        }
        totalListings
        countListingsInSync
        countListingsBeingUpdated
        countListingsLinked
        countListingsSubmitted
        countListingsActionRequired
      }
    }
  }
`;

/**
 * __useLocationsReprtQuery__
 *
 * To run a query within a React component, call `useLocationsReprtQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsReprtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsReprtQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationsReprtQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LocationsReprtQuery, LocationsReprtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LocationsReprtQuery, LocationsReprtQueryVariables>(LocationsReprtDocument, options);
}
export function useLocationsReprtLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsReprtQuery, LocationsReprtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LocationsReprtQuery, LocationsReprtQueryVariables>(
    LocationsReprtDocument,
    options
  );
}
export type LocationsReprtQueryHookResult = ReturnType<typeof useLocationsReprtQuery>;
export type LocationsReprtLazyQueryHookResult = ReturnType<typeof useLocationsReprtLazyQuery>;
export type LocationsReprtQueryResult = ApolloReactCommon.QueryResult<
  LocationsReprtQuery,
  LocationsReprtQueryVariables
>;
export const LoginUserDocument = gql`
  mutation loginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      isLoggedIn
    }
  }
`;
export type LoginUserMutationFn = ApolloReactCommon.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = ApolloReactCommon.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const ConversationsDocument = gql`
  query Conversations($limit: Int, $offset: Int) {
    conversations(limit: $limit, offset: $offset) {
      totalCount
      nodes {
        id
        subject
        createdAt
      }
    }
  }
`;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useConversationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
}
export function useConversationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
}
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = ApolloReactCommon.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const CreateConversationDocument = gql`
  mutation CreateConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      conversation {
        id
      }
    }
  }
`;
export type CreateConversationMutationFn = ApolloReactCommon.MutationFunction<
  CreateConversationMutation,
  CreateConversationMutationVariables
>;

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConversationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(
    CreateConversationDocument,
    options
  );
}
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = ApolloReactCommon.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateConversationMutation,
  CreateConversationMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserProfileDocument = gql`
  query UserProfile($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      emailPreference
    }
  }
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export function useUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = ApolloReactCommon.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const CustomersDocument = gql`
  query Customers($search: String, $limit: Int, $offset: Int) {
    customers(search: $search, limit: $limit, offset: $offset) {
      totalCount
      nodes {
        id
        name
        partner {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export function useCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = ApolloReactCommon.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const Sso_LoginUserDocument = gql`
  mutation SSO_LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      isLoggedIn
      userId
    }
  }
`;
export type Sso_LoginUserMutationFn = ApolloReactCommon.MutationFunction<
  Sso_LoginUserMutation,
  Sso_LoginUserMutationVariables
>;

/**
 * __useSso_LoginUserMutation__
 *
 * To run a mutation, you first call `useSso_LoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSso_LoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoLoginUserMutation, { data, loading, error }] = useSso_LoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSso_LoginUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Sso_LoginUserMutation, Sso_LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<Sso_LoginUserMutation, Sso_LoginUserMutationVariables>(
    Sso_LoginUserDocument,
    options
  );
}
export type Sso_LoginUserMutationHookResult = ReturnType<typeof useSso_LoginUserMutation>;
export type Sso_LoginUserMutationResult = ApolloReactCommon.MutationResult<Sso_LoginUserMutation>;
export type Sso_LoginUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Sso_LoginUserMutation,
  Sso_LoginUserMutationVariables
>;
export const TrackSsoEventDocument = gql`
  mutation TrackSsoEvent($input: CreateUserInteractionEventInput!) {
    createUserInteractionEvent(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type TrackSsoEventMutationFn = ApolloReactCommon.MutationFunction<
  TrackSsoEventMutation,
  TrackSsoEventMutationVariables
>;

/**
 * __useTrackSsoEventMutation__
 *
 * To run a mutation, you first call `useTrackSsoEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackSsoEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackSsoEventMutation, { data, loading, error }] = useTrackSsoEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackSsoEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TrackSsoEventMutation, TrackSsoEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TrackSsoEventMutation, TrackSsoEventMutationVariables>(
    TrackSsoEventDocument,
    options
  );
}
export type TrackSsoEventMutationHookResult = ReturnType<typeof useTrackSsoEventMutation>;
export type TrackSsoEventMutationResult = ApolloReactCommon.MutationResult<TrackSsoEventMutation>;
export type TrackSsoEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TrackSsoEventMutation,
  TrackSsoEventMutationVariables
>;
export const TaskWorkspaceDocument = gql`
  query TaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      taskType {
        id
        name
      }
    }
  }
`;

/**
 * __useTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>(TaskWorkspaceDocument, options);
}
export function useTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>(TaskWorkspaceDocument, options);
}
export type TaskWorkspaceQueryHookResult = ReturnType<typeof useTaskWorkspaceQuery>;
export type TaskWorkspaceLazyQueryHookResult = ReturnType<typeof useTaskWorkspaceLazyQuery>;
export type TaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>;
export const SubmitContentCalendarReviewDocument = gql`
  mutation SubmitContentCalendarReview($input: SubmitContentCalendarReviewInput!) {
    submitContentCalendarReview(input: $input) {
      task {
        id
      }
    }
  }
`;
export type SubmitContentCalendarReviewMutationFn = ApolloReactCommon.MutationFunction<
  SubmitContentCalendarReviewMutation,
  SubmitContentCalendarReviewMutationVariables
>;

/**
 * __useSubmitContentCalendarReviewMutation__
 *
 * To run a mutation, you first call `useSubmitContentCalendarReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitContentCalendarReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitContentCalendarReviewMutation, { data, loading, error }] = useSubmitContentCalendarReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitContentCalendarReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitContentCalendarReviewMutation,
    SubmitContentCalendarReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitContentCalendarReviewMutation,
    SubmitContentCalendarReviewMutationVariables
  >(SubmitContentCalendarReviewDocument, options);
}
export type SubmitContentCalendarReviewMutationHookResult = ReturnType<typeof useSubmitContentCalendarReviewMutation>;
export type SubmitContentCalendarReviewMutationResult =
  ApolloReactCommon.MutationResult<SubmitContentCalendarReviewMutation>;
export type SubmitContentCalendarReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitContentCalendarReviewMutation,
  SubmitContentCalendarReviewMutationVariables
>;
export const ContentCalendarReviewTaskWorkspaceDocument = gql`
  query ContentCalendarReviewTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      status
      customerProposal {
        id
        proposedOrderTopics {
          ...UI_ProposedOrderTopic
        }
      }
    }
  }
  ${Ui_ProposedOrderTopicFragmentDoc}
`;

/**
 * __useContentCalendarReviewTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentCalendarReviewTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCalendarReviewTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCalendarReviewTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentCalendarReviewTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentCalendarReviewTaskWorkspaceQuery,
    ContentCalendarReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ContentCalendarReviewTaskWorkspaceQuery,
    ContentCalendarReviewTaskWorkspaceQueryVariables
  >(ContentCalendarReviewTaskWorkspaceDocument, options);
}
export function useContentCalendarReviewTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentCalendarReviewTaskWorkspaceQuery,
    ContentCalendarReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContentCalendarReviewTaskWorkspaceQuery,
    ContentCalendarReviewTaskWorkspaceQueryVariables
  >(ContentCalendarReviewTaskWorkspaceDocument, options);
}
export type ContentCalendarReviewTaskWorkspaceQueryHookResult = ReturnType<
  typeof useContentCalendarReviewTaskWorkspaceQuery
>;
export type ContentCalendarReviewTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useContentCalendarReviewTaskWorkspaceLazyQuery
>;
export type ContentCalendarReviewTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentCalendarReviewTaskWorkspaceQuery,
  ContentCalendarReviewTaskWorkspaceQueryVariables
>;
export const SubmitContentReviewDocument = gql`
  mutation SubmitContentReview($input: SubmitContentReviewInput!) {
    submitContentReview(input: $input) {
      task {
        id
      }
    }
  }
`;
export type SubmitContentReviewMutationFn = ApolloReactCommon.MutationFunction<
  SubmitContentReviewMutation,
  SubmitContentReviewMutationVariables
>;

/**
 * __useSubmitContentReviewMutation__
 *
 * To run a mutation, you first call `useSubmitContentReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitContentReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitContentReviewMutation, { data, loading, error }] = useSubmitContentReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitContentReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitContentReviewMutation, SubmitContentReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SubmitContentReviewMutation, SubmitContentReviewMutationVariables>(
    SubmitContentReviewDocument,
    options
  );
}
export type SubmitContentReviewMutationHookResult = ReturnType<typeof useSubmitContentReviewMutation>;
export type SubmitContentReviewMutationResult = ApolloReactCommon.MutationResult<SubmitContentReviewMutation>;
export type SubmitContentReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitContentReviewMutation,
  SubmitContentReviewMutationVariables
>;
export const ContentReviewTaskWorkspaceDocument = gql`
  query ContentReviewTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      status
      contentItem {
        id
        title
        content
        metaTitle
        metaDescription
        thumbnailFile {
          id
          name
          url
        }
      }
      partnerProduct {
        id
        name
        product {
          id
          name
          description
        }
      }
    }
  }
`;

/**
 * __useContentReviewTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentReviewTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentReviewTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentReviewTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentReviewTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentReviewTaskWorkspaceQuery,
    ContentReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentReviewTaskWorkspaceQuery, ContentReviewTaskWorkspaceQueryVariables>(
    ContentReviewTaskWorkspaceDocument,
    options
  );
}
export function useContentReviewTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentReviewTaskWorkspaceQuery,
    ContentReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentReviewTaskWorkspaceQuery, ContentReviewTaskWorkspaceQueryVariables>(
    ContentReviewTaskWorkspaceDocument,
    options
  );
}
export type ContentReviewTaskWorkspaceQueryHookResult = ReturnType<typeof useContentReviewTaskWorkspaceQuery>;
export type ContentReviewTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useContentReviewTaskWorkspaceLazyQuery>;
export type ContentReviewTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentReviewTaskWorkspaceQuery,
  ContentReviewTaskWorkspaceQueryVariables
>;
export const ContentReview_SaveReviewTaskContentItemDocument = gql`
  mutation ContentReview_SaveReviewTaskContentItem($input: SaveReviewTaskContentItemInput!) {
    saveReviewTaskContentItem(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type ContentReview_SaveReviewTaskContentItemMutationFn = ApolloReactCommon.MutationFunction<
  ContentReview_SaveReviewTaskContentItemMutation,
  ContentReview_SaveReviewTaskContentItemMutationVariables
>;

/**
 * __useContentReview_SaveReviewTaskContentItemMutation__
 *
 * To run a mutation, you first call `useContentReview_SaveReviewTaskContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentReview_SaveReviewTaskContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentReviewSaveReviewTaskContentItemMutation, { data, loading, error }] = useContentReview_SaveReviewTaskContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentReview_SaveReviewTaskContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentReview_SaveReviewTaskContentItemMutation,
    ContentReview_SaveReviewTaskContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentReview_SaveReviewTaskContentItemMutation,
    ContentReview_SaveReviewTaskContentItemMutationVariables
  >(ContentReview_SaveReviewTaskContentItemDocument, options);
}
export type ContentReview_SaveReviewTaskContentItemMutationHookResult = ReturnType<
  typeof useContentReview_SaveReviewTaskContentItemMutation
>;
export type ContentReview_SaveReviewTaskContentItemMutationResult =
  ApolloReactCommon.MutationResult<ContentReview_SaveReviewTaskContentItemMutation>;
export type ContentReview_SaveReviewTaskContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentReview_SaveReviewTaskContentItemMutation,
  ContentReview_SaveReviewTaskContentItemMutationVariables
>;
export const ContentStrategyReviewDocument = gql`
  query ContentStrategyReview($taskId: Int!) {
    task(taskId: $taskId) {
      id
      status
      customerProposal {
        id
        summary
        orderContentStrategies(sort: ORDER_START_DATE_ASC) {
          ...ContentStrategyReview_OrderContentStrategy
        }
      }
    }
  }
  ${ContentStrategyReview_OrderContentStrategyFragmentDoc}
`;

/**
 * __useContentStrategyReviewQuery__
 *
 * To run a query within a React component, call `useContentStrategyReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyReviewQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentStrategyReviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ContentStrategyReviewQuery, ContentStrategyReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyReviewQuery, ContentStrategyReviewQueryVariables>(
    ContentStrategyReviewDocument,
    options
  );
}
export function useContentStrategyReviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentStrategyReviewQuery, ContentStrategyReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentStrategyReviewQuery, ContentStrategyReviewQueryVariables>(
    ContentStrategyReviewDocument,
    options
  );
}
export type ContentStrategyReviewQueryHookResult = ReturnType<typeof useContentStrategyReviewQuery>;
export type ContentStrategyReviewLazyQueryHookResult = ReturnType<typeof useContentStrategyReviewLazyQuery>;
export type ContentStrategyReviewQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyReviewQuery,
  ContentStrategyReviewQueryVariables
>;
export const SaveContentStrategyReviewDocument = gql`
  mutation SaveContentStrategyReview($input: SaveContentStrategyReviewInput!) {
    saveContentStrategyReview(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type SaveContentStrategyReviewMutationFn = ApolloReactCommon.MutationFunction<
  SaveContentStrategyReviewMutation,
  SaveContentStrategyReviewMutationVariables
>;

/**
 * __useSaveContentStrategyReviewMutation__
 *
 * To run a mutation, you first call `useSaveContentStrategyReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContentStrategyReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContentStrategyReviewMutation, { data, loading, error }] = useSaveContentStrategyReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveContentStrategyReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveContentStrategyReviewMutation,
    SaveContentStrategyReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveContentStrategyReviewMutation, SaveContentStrategyReviewMutationVariables>(
    SaveContentStrategyReviewDocument,
    options
  );
}
export type SaveContentStrategyReviewMutationHookResult = ReturnType<typeof useSaveContentStrategyReviewMutation>;
export type SaveContentStrategyReviewMutationResult =
  ApolloReactCommon.MutationResult<SaveContentStrategyReviewMutation>;
export type SaveContentStrategyReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveContentStrategyReviewMutation,
  SaveContentStrategyReviewMutationVariables
>;
export const SubmitKeywordReviewDocument = gql`
  mutation SubmitKeywordReview($input: SubmitKeywordReviewInput!) {
    submitKeywordReview(input: $input) {
      done
    }
  }
`;
export type SubmitKeywordReviewMutationFn = ApolloReactCommon.MutationFunction<
  SubmitKeywordReviewMutation,
  SubmitKeywordReviewMutationVariables
>;

/**
 * __useSubmitKeywordReviewMutation__
 *
 * To run a mutation, you first call `useSubmitKeywordReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitKeywordReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitKeywordReviewMutation, { data, loading, error }] = useSubmitKeywordReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitKeywordReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitKeywordReviewMutation, SubmitKeywordReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SubmitKeywordReviewMutation, SubmitKeywordReviewMutationVariables>(
    SubmitKeywordReviewDocument,
    options
  );
}
export type SubmitKeywordReviewMutationHookResult = ReturnType<typeof useSubmitKeywordReviewMutation>;
export type SubmitKeywordReviewMutationResult = ApolloReactCommon.MutationResult<SubmitKeywordReviewMutation>;
export type SubmitKeywordReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitKeywordReviewMutation,
  SubmitKeywordReviewMutationVariables
>;
export const KeywordResearchReviewTaskWorkspaceDocument = gql`
  query KeywordResearchReviewTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      status
      keywordReview {
        id
        summary
        primaryProposedKeywords: proposedCustomerKeywords(isPrimary: true, isRejected: false) {
          ...UI_ProposedKeyword
        }
        haloProposedKeywords: proposedCustomerKeywords(isPrimary: false, isRejected: false) {
          ...UI_ProposedKeyword
        }
      }
    }
  }
  ${Ui_ProposedKeywordFragmentDoc}
`;

/**
 * __useKeywordResearchReviewTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useKeywordResearchReviewTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordResearchReviewTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordResearchReviewTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useKeywordResearchReviewTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    KeywordResearchReviewTaskWorkspaceQuery,
    KeywordResearchReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    KeywordResearchReviewTaskWorkspaceQuery,
    KeywordResearchReviewTaskWorkspaceQueryVariables
  >(KeywordResearchReviewTaskWorkspaceDocument, options);
}
export function useKeywordResearchReviewTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KeywordResearchReviewTaskWorkspaceQuery,
    KeywordResearchReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    KeywordResearchReviewTaskWorkspaceQuery,
    KeywordResearchReviewTaskWorkspaceQueryVariables
  >(KeywordResearchReviewTaskWorkspaceDocument, options);
}
export type KeywordResearchReviewTaskWorkspaceQueryHookResult = ReturnType<
  typeof useKeywordResearchReviewTaskWorkspaceQuery
>;
export type KeywordResearchReviewTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useKeywordResearchReviewTaskWorkspaceLazyQuery
>;
export type KeywordResearchReviewTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  KeywordResearchReviewTaskWorkspaceQuery,
  KeywordResearchReviewTaskWorkspaceQueryVariables
>;
export const WorkSummaryDocument = gql`
  query WorkSummary($limit: Int, $offset: Int, $startDate: DateQuery) {
    orders(limit: $limit, offset: $offset, startDate: $startDate, sort: STARTED_AT_ASC) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...WorkSummary_Order
      }
    }
    amTasks {
      nodes {
        ...WorkSummary_AmTask
      }
    }
  }
  ${WorkSummary_OrderFragmentDoc}
  ${WorkSummary_AmTaskFragmentDoc}
`;

/**
 * __useWorkSummaryQuery__
 *
 * To run a query within a React component, call `useWorkSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkSummaryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useWorkSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<WorkSummaryQuery, WorkSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WorkSummaryQuery, WorkSummaryQueryVariables>(WorkSummaryDocument, options);
}
export function useWorkSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkSummaryQuery, WorkSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WorkSummaryQuery, WorkSummaryQueryVariables>(WorkSummaryDocument, options);
}
export type WorkSummaryQueryHookResult = ReturnType<typeof useWorkSummaryQuery>;
export type WorkSummaryLazyQueryHookResult = ReturnType<typeof useWorkSummaryLazyQuery>;
export type WorkSummaryQueryResult = ApolloReactCommon.QueryResult<WorkSummaryQuery, WorkSummaryQueryVariables>;
