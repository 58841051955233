import {
  Heading,
  Link,
  Text,
  Tooltip,
  theme,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Badge,
} from "@chakra-ui/react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import AppIcon from "../../components/app-icon";
import { HelpText } from "../../components/help-text";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { MMM_D_YYYY, MMM_D_YYYY_H_MM_A } from "../../constants/constants";
import { ORDER_STATUS } from "../../constants/order-status";
import { AppColors } from "../../core/custom-theme";
import { WorkSummary_AmTaskFragment, WorkSummary_OrderFragment } from "../../generated/graphql";
import { OrderStatus } from "./order-status";
import { useWorkSummaryMonth } from "./use-work-summary-month";

interface Props {
  isCurrent?: boolean;
  isPast?: boolean;
  monthName: string;
  orders: WorkSummary_OrderFragment[];
  amTasks: WorkSummary_AmTaskFragment[];
}

export function WorkSummaryMonth(props: Props) {
  const { monthName, orders, isPast, amTasks } = props;
  const isCompleted = orders.every((x) => x.status.id === ORDER_STATUS.COMPLETED.id);
  const { orderLink, onOpenLinkModal, onCloseLinkModal } = useWorkSummaryMonth();

  return (
    <>
      <PanelGroup isCollapsible isCollapsedByDefault={isPast && isCompleted}>
        <PanelTop showBottomBorder>
          <Heading size="md">{monthName}</Heading>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={orders.length === 0 && amTasks.length === 0} isLoading={false}>
            <TableHead>
              <TableRow key="head">
                <TableHeaderCell>
                  Product
                  <HelpText standardLeftMargin>The name of the product the order provided.</HelpText>
                </TableHeaderCell>
                <TableHeaderCell>
                  Topic
                  <HelpText standardLeftMargin>The title of the content delivered.</HelpText>
                </TableHeaderCell>
                <TableHeaderCell>Start Date</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>
                  Link
                  <HelpText standardLeftMargin>
                    Here we will provide a link to items published on your behalf (Guest Articles, Business Listings,
                    etc.). Links to items you publish will not appear here.
                  </HelpText>
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>
                    {order.workType}
                    <Text fontSize="sm" color={theme.colors.gray[500]}>
                      Order #{order.id}
                    </Text>
                  </TableCell>
                  <TableCell>{order.topic ?? "-"}</TableCell>
                  <TableCell>{DateTime.fromJSDate(order.startDate).toFormat(MMM_D_YYYY)}</TableCell>
                  <TableCell>
                    <OrderStatus
                      status={order.status}
                      startDate={DateTime.fromJSDate(order.startDate).toFormat(MMM_D_YYYY_H_MM_A)}
                    />
                  </TableCell>
                  <TableCell>
                    {!order.resultUrl && (
                      <Text color={AppColors.textGray}>
                        <AppIcon icon={faLink} />
                      </Text>
                    )}
                    {order.resultUrl && <AppIcon icon={faLink} onClick={onOpenLinkModal.bind(null, order)} />}
                  </TableCell>
                </TableRow>
              ))}
              {amTasks.map((amTask) => (
                <TableRow key={amTask.id}>
                  <TableCell>{amTask.taskType}</TableCell>
                  <TableCell>{amTask.info ?? "-"}</TableCell>
                  <TableCell>{DateTime.fromJSDate(amTask.completedAt).toFormat(MMM_D_YYYY)}</TableCell>
                  <TableCell>
                    <Badge cursor="help" colorScheme="green">
                      Completed
                    </Badge>
                  </TableCell>
                  <TableCell>{"-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
      <Modal onClose={onCloseLinkModal} size="xl" isOpen={Boolean(orderLink)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Result URL</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table isLoading={false} isEmpty={false}>
              <TableBody>
                <TableRow key="url">
                  <TableCell key="label-url">Result URL</TableCell>
                  <TableCell key="value-url">
                    <Text>
                      <Tooltip label="View Deliverable (opens in new tab)" hasArrow aria-label="View Deliverable">
                        <Link color={AppColors.linkBlue} href={orderLink?.resultUrl ?? ""} target="_blank">
                          {orderLink?.resultUrl ?? "-"}
                        </Link>
                      </Tooltip>
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow key="domain-authority">
                  <TableCell key="label-domain-authority">Domain Authority</TableCell>
                  <TableCell key="value-domain-authority">{orderLink?.resultUrlDomainAuthority ?? "-"}</TableCell>
                </TableRow>
                <TableRow key="spam-score">
                  <TableCell key="label-spam-score">Spam Score</TableCell>
                  <TableCell key="value-spam-score">{orderLink?.linkLocation?.spam ?? "-"}</TableCell>
                </TableRow>
                <TableRow key="authority-score">
                  <TableCell key="label-authority-score">Authority Score</TableCell>
                  <TableCell key="value-authority-score">{orderLink?.linkLocation?.authorityScore ?? "-"}</TableCell>
                </TableRow>
                <TableRow key="traffic">
                  <TableCell key="label-traffic">Traffic</TableCell>
                  <TableCell key="value-traffic">
                    {orderLink?.resultUrlTraffic ?? orderLink?.linkLocation?.monthlyTraffic ?? "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
