interface NavItemDefinition {
  name: string;
  to: string;
  visible: boolean;
}

interface Props {
  analyticsVisible: boolean;
  reviewsVisible: boolean;
}

export function getMainNavDefinition(props: Props) {
  const { analyticsVisible, reviewsVisible } = props;

  const navItems: NavItemDefinition[] = [
    {
      name: "Dashboard",
      to: "/dashboard",
      visible: true,
    },
    {
      name: "Work Summary",
      to: "/work-summary",
      visible: true,
    },
    {
      name: "Keywords",
      to: "/keyword-rankings",
      visible: true,
    },
    {
      name: "Analytics",
      to: "/analytics",
      visible: analyticsVisible,
    },
    {
      name: "Messages",
      to: "/messages",
      visible: true,
    },
    {
      name: "Account",
      to: "/account",
      visible: true,
    },
    {
      name: "Local Listings",
      to: "/locations-report",
      visible: true,
    },
    {
      name: "Reviews",
      to: "/location-reviews",
      visible: reviewsVisible,
    },
  ];

  return navItems;
}
