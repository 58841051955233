import { useState } from "react";
import AppLink from "../../app-link";
import AppIcon from "../../app-icon";
import { faBars, faUserCircle, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { getMainNavDefinition } from "../main-nav-definition";
import { useLocation } from "react-router-dom";
import { MainNavSmallLink } from "./main-nav-small-link";
import { MainNavSmallProfile } from "./main-nav-small-profile";
import {
  Box,
  Drawer,
  Button,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Image,
  Text,
} from "@chakra-ui/react";
import { useMainMenuPartnerLogoQuery } from "../../../generated/graphql";

interface Props {
  hasSecondaryNav?: boolean;
}

export function MainNavSmall(props: Props) {
  const { hasSecondaryNav } = props;
  const [isMainNavOpen, setIsMainNavOpen] = useState(false);
  const [isProfileNavOpen, setIsProfileNavOpen] = useState(false);
  const location = useLocation();

  const { data } = useMainMenuPartnerLogoQuery({
    fetchPolicy: "cache-and-network",
  });
  const url = data?.customer?.partner?.reportingLogoUrl ?? null;

  let isReviewEnabled = false;
  for (const customerBusinessListingSpec of data?.customer?.customerBusinessListingSpecs.nodes ?? []) {
    if (customerBusinessListingSpec.isReviewEnabled) {
      isReviewEnabled = true;
    }
  }

  const navDefinitions = getMainNavDefinition({
    analyticsVisible: !!data?.customer?.analyticsReportingInlineFrameUrl,
    reviewsVisible: isReviewEnabled,
  });

  return (
    <Box
      height="64px"
      backgroundColor="#333333"
      position="sticky"
      top="0"
      zIndex={999}
      display="grid"
      alignItems="center"
      gridTemplateColumns="1fr 205px 1fr"
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Drawer
        isOpen={isMainNavOpen}
        placement="left"
        isFullHeight
        onClose={() => setIsMainNavOpen(false)}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            {navDefinitions.map((navItem) => {
              if (!navItem.visible) {
                return null;
              }
              return (
                <Box key={navItem.name} marginBottom={2} fontSize="1rem">
                  <MainNavSmallLink
                    to={navItem.to}
                    isActive={location.pathname.startsWith(navItem.to)}
                    name={navItem.name}
                  />
                </Box>
              );
            })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={isProfileNavOpen}
        placement="right"
        onClose={() => setIsProfileNavOpen(false)}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <MainNavSmallProfile />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box gridColumn={1} paddingLeft={1}>
        <Button
          variant="ghost"
          backgroundColor="#333333"
          _hover={{ backgroundColor: "#666666" }}
          _active={{ backgroundColor: "#777777" }}
          onClick={() => setIsMainNavOpen(true)}
        >
          <AppIcon icon={faBars} color="white" />
        </Button>
      </Box>
      <Box gridColumn={2} textAlign="center">
        <AppLink to="/">
          {!url && (
            <>
              <Text marginLeft={2}>
                <AppIcon icon={faChartLine} standardRightMargin />
                {data?.customer?.partner.name ?? "Reporting Smarts"}
              </Text>
            </>
          )}
          {url && <Image objectFit="contain" src={url} maxHeight={50} crossOrigin="" />}
        </AppLink>
      </Box>
      <Box gridColumn={3} textAlign="right" paddingRight={1}>
        <Button
          variant="ghost"
          backgroundColor="#333333"
          _hover={{ backgroundColor: "#666666" }}
          _active={{ backgroundColor: "#777777" }}
          onClick={() => setIsProfileNavOpen(true)}
        >
          <AppIcon icon={faUserCircle} color="white" />
        </Button>
      </Box>
    </Box>
  );
}
